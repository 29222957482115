import moment from 'moment';

import { utilService } from '../services';
import {
  CLEAR_ERRORS,
  GET_WEEK_DATA,
  GET_WEEK_DATA_SUCCESS,
  GET_WEEK_DATA_FAIL,
  GET_DAY_DATA,
  GET_DAY_DATA_SUCCESS,
  GET_DAY_DATA_FAIL,
  GET_WORKLOAD_HISTORY,
  GET_WORKLOAD_HISTORY_SUCCESS,
  GET_WORKLOAD_HISTORY_FAIL,
  GET_PROJECT_BUDGETS,
  GET_PROJECT_BUDGETS_SUCCESS,
  GET_PROJECT_BUDGETS_FAIL,
  GET_TIMETRACKINGS,
  GET_TIMETRACKINGS_SUCCESS,
  GET_TIMETRACKINGS_FAIL,
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_VACATION_DATA,
  GET_USER_VACATION_DATA_SUCCESS,
  GET_USER_VACATION_DATA_FAIL,
  SET_USER_VACATION_DATA,
  SET_USER_VACATION_DATA_SUCCESS,
  SET_USER_VACATION_DATA_FAIL,
  ADD_VACATION,
  ADD_VACATION_SUCCESS,
  ADD_VACATION_FAIL,
  ADD_TIMETRACKING,
  ADD_TIMETRACKING_SUCCESS,
  ADD_TIMETRACKING_FAIL,
  REMOVE_TIMETRACKING,
  REMOVE_TIMETRACKING_SUCCESS,
  REMOVE_TIMETRACKING_FAIL,
  EDIT_TIMETRACKING,
  EDIT_TIMETRACKING_SUCCESS,
  EDIT_TIMETRACKING_FAIL,
  START_TIMETRACKING,
  START_TIMETRACKING_SUCCESS,
  START_TIMETRACKING_FAIL,
  CONTINUE_TIMETRACKING,
  CONTINUE_TIMETRACKING_SUCCESS,
  CONTINUE_TIMETRACKING_FAIL,
} from '../actions/types';
const INITIAL_STATE = {
  weekData: [],
  userIdForActiveData: 0,
  dayData: {
    date: moment(),
    isCompleted: false,
    timeTrackings: [],
    targetTime: 0,
    workDoneMinutes: 0,
    workDoneDuration: '',
    workDoneIndustrial: 0,
    targetMinutes: 0,
    targetTimeIndustrial: 0,
    difference: 0,
  },
  workloadHistory: {},
  projectBudgets: [],
  timeTrackings: {},
  contacts: [],
  projects: [],
  activities: [],
  users: [],
  loading: false,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  const errorKey = action.type.toLowerCase();
  switch (action.type) {
    case GET_WEEK_DATA:
    case GET_DAY_DATA:
    case GET_WORKLOAD_HISTORY:
    case GET_TIMETRACKINGS:
    case GET_PROJECTS:
    case GET_ACTIVITIES:
    case GET_USERS:
    case GET_USER_VACATION_DATA:
    case SET_USER_VACATION_DATA:
    case ADD_VACATION:
    case ADD_TIMETRACKING:
    case REMOVE_TIMETRACKING:
    case EDIT_TIMETRACKING:
    case START_TIMETRACKING:
    case CONTINUE_TIMETRACKING:
      return { ...state, loading: true };
    case CLEAR_ERRORS:
      return { ...state, errors: {} };

    case GET_PROJECT_BUDGETS:
      return state; // noop

    case GET_WEEK_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        weekData: action.weekData
      };
    case GET_DAY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        dayData: action.dayData
      };
    case GET_WORKLOAD_HISTORY_SUCCESS:
    return {
      ...state,
      loading: false,
      errors: utilService.clearError(errorKey, state.errors),
      workloadHistory: {
        ...state.workloadHistory,
        [moment(Object.keys(action.workloadHistory)[0]).get('year')]: action.workloadHistory
      }
    };
    case GET_PROJECT_BUDGETS_SUCCESS:
    return {
      ...state,
      loading: false,
      errors: utilService.clearError(errorKey, state.errors),
      projectBudgets: action.budgetData
    };
    case GET_TIMETRACKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        timeTrackings: action.timeTrackings,
        userIdForActiveData: action.userIdForActiveData
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        projects: action.projects,
        contacts: action.contacts,
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        activities: action.activities
      };
    case GET_USERS_SUCCESS:
    case GET_USER_VACATION_DATA_SUCCESS:
    case SET_USER_VACATION_DATA_SUCCESS:
    return {
      ...state,
      loading: false,
      errors: utilService.clearError(errorKey, state.errors),
      users: action.users
    };
    case ADD_VACATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        weekData: action.newWeekData,
        dayData: action.newDayData,
        timeTrackings: action.newTimeTrackings
      };
    }
    case ADD_TIMETRACKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        weekData: action.newWeekData,
        dayData: action.newDayData,
        timeTrackings: action.newTimeTrackings
      };
    }
    case REMOVE_TIMETRACKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        weekData: action.newWeekData,
        dayData: action.newDayData,
        timeTrackings: action.newTimeTrackings
      };
    }
    case EDIT_TIMETRACKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        weekData: action.newWeekData,
        dayData: action.newDayData,
        timeTrackings: action.editedTimeTrackings
      };
    }
    case START_TIMETRACKING_SUCCESS: {
      const newDayData = {...state.dayData};
      newDayData.timeTrackings.push(utilService.resolveTimeTracking(action.timeTracking, state));
      const newTimeTrackings = {...state.timeTrackings};
      if (newTimeTrackings[action.timeTracking.date] !== undefined) {
        newTimeTrackings[action.timeTracking.date].push(action.timeTracking);
      } else {
        newTimeTrackings[action.timeTracking.date] = [action.timeTracking];
      }

      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        dayData: newDayData,
        timeTrackings: newTimeTrackings
      };
    }
    case CONTINUE_TIMETRACKING_SUCCESS: {
      const newDayData = {...state.dayData};
      const indexInDayEntries = newDayData.timeTrackings.findIndex(t => t.id === action.timeTracking.id);
      newDayData.timeTrackings.splice(indexInDayEntries, 1, utilService.resolveTimeTracking(action.timeTracking, state));

      const newTimeTrackings = {...state.timeTrackings};
      const indexInTimeTrackings = newTimeTrackings[action.timeTracking.date].findIndex(t => t.id === action.timeTracking.id);
      newTimeTrackings[action.timeTracking.date].splice(indexInTimeTrackings, 1, action.timeTracking);

      return {
        ...state,
        loading: false,
        errors: utilService.clearError(errorKey, state.errors),
        dayData: newDayData,
        timeTrackings: newTimeTrackings
      };
    }

    case GET_WEEK_DATA_FAIL:
    case GET_DAY_DATA_FAIL:
    case GET_WORKLOAD_HISTORY_FAIL:
    case GET_PROJECT_BUDGETS_FAIL:
    case GET_TIMETRACKINGS_FAIL:
    case GET_PROJECTS_FAIL:
    case GET_ACTIVITIES_FAIL:
    case GET_USERS_FAIL:
    case GET_USER_VACATION_DATA_FAIL:
    case SET_USER_VACATION_DATA_FAIL:
    case ADD_VACATION_FAIL:
    case ADD_TIMETRACKING_FAIL:
    case REMOVE_TIMETRACKING_FAIL:
    case EDIT_TIMETRACKING_FAIL:
    case START_TIMETRACKING_FAIL:
    case CONTINUE_TIMETRACKING_FAIL:
      const newErrors = {...state.errors};
      newErrors[errorKey] = action.error;
      return { ...state, loading: false, errors: newErrors };
    default:
      return state;
  }
};
