import { utilService } from '../services';
import {
  CLEAR_ERRORS,
  GET_FAVORITES,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_FAIL,
  ADD_FAVORITE,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAIL,
  REMOVE_FAVORITE,
  REMOVE_FAVORITE_SUCCESS,
  REMOVE_FAVORITE_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
  favorites: [],
  loading: false,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  const errorKey = action.type.toLowerCase();
  switch (action.type) {
    case GET_FAVORITES:
    case ADD_FAVORITE:
    case REMOVE_FAVORITE:
      return { ...state, loading: true };

    case CLEAR_ERRORS:
      return { ...state, errors: {} };

    case GET_FAVORITES_SUCCESS:
      return { ...state, loading: false, favorites: action.favorites };
    case ADD_FAVORITE_SUCCESS:
      // increment from greatest existing id, or start at 1
      const id = state.favorites.length > 0 ? Math.max(...state.favorites.map(f => f.id)) + 1 : 1;
      // create new favorite with id
      const favoriteWithId = {...action.newFavorite, id};
      const newSortedFavorites = [...state.favorites, favoriteWithId]
      .sort((a,b) => utilService.sortByString(a.combinedName, b.combinedName));

      return { ...state, loading: false, favorites: newSortedFavorites };
    case REMOVE_FAVORITE_SUCCESS:
      // get index of the favorite to remove it
      const index = state.favorites.map(f => f.id).indexOf(action.id);
      if (index !== -1) {
        const newFavorites = [...state.favorites];
        newFavorites.splice(index, 1);
        return { ...state, loading: false, favorites: newFavorites };
      }
      return { ...state, loading: false };

    case GET_FAVORITES_FAIL:
    case ADD_FAVORITE_FAIL:
    case REMOVE_FAVORITE_FAIL:
      const newErrors = {...state.errors};
      newErrors[errorKey] = action.error;
      return { ...state, loading: false, errors: newErrors };
    default:
      return state;
  }
};
