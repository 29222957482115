import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const SameColorSwitch = withStyles({
  switchBase: {
    color: '#1C9688',
  },
  bar: {
    color: '#1C9688',
    backgroundColor: '#1C9688',
  },
})(Switch);

export default SameColorSwitch;