import moment from 'moment';

function getWeekForDate(momentDate) {
  const jsDate = momentDate.toDate();
  const startOfWeek = moment(jsDate).startOf('isoWeek');
  const endOfWeek = moment(jsDate).endOf('isoWeek');
  const week = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    week.push(day);
    day = day.clone().add(1, 'd');
  }

  return week;
}

function isToday(momentDate) {
  return moment().startOf('date').diff(momentDate.startOf('date')) === 0;
}

function pad(num) {
  return (num < 10 ? '0' : '') + num;
}

function prettyPrintIndustrialHours(industrialHours) {
  if (typeof industrialHours !== "number") {
    return industrialHours;
  }

  const hoursFormatted = Number(industrialHours.toFixed(2)) !== 0 ?
  industrialHours.toFixed(2) : '0.00'; // avoid negative zero

  return `${hoursFormatted} h`;
}

function getDurationFromMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${pad(hours)}:${pad(minutesLeft)}`;
}

// use: adds up durations (of timetrackings)
// parameters: array of duration strings
// returns: summed minutes of all durations
function getMinutesFromDurations(durations) {
  if (!durations || durations.length === 0) {
    return 0;
  }

  return durations.reduce((a,b) => a + getMinutesFromDuration(b), 0);
}

function getMinutesFromDuration(durationString) {
  const parsedMinutes = minutesFromHoursMinutesString(durationString);
  return !isNaN(parsedMinutes) ? parsedMinutes : 0;
}

function getParsedDuration(durationString) {
  // try parse hours:minutes
  const parsedMinutes = minutesFromHoursMinutesString(durationString);
  if (!isNaN(parsedMinutes)) {
    return getDurationFromMinutes(parsedMinutes);
  }

  // try parse decimal hours - e.g. 8.4
  const minutes = durationString.replace(',', '.') * 60;
  if (!isNaN(minutes)) {
    return getDurationFromMinutes(Math.round(minutes));
  }
}

function minutesFromHoursMinutesString(hoursMinutes) {
  if (typeof hoursMinutes !== 'string') {
    return NaN;
  }

  const [hoursPart, minutesPart] = hoursMinutes.split(':');
  if (hoursPart.length > 0 && minutesPart?.length === 2) {
    const hoursNumber = Number(hoursPart);
    const minutesNumber = Number(minutesPart);

    if (!isNaN(hoursNumber) && !isNaN(minutesNumber)) {
      return (hoursNumber * 60) + minutesNumber;
    }
  }

  return NaN;
}

const dateService = {
  getWeekForDate,
  isToday,
  prettyPrintIndustrialHours,
  getDurationFromMinutes,
  getMinutesFromDurations,
  getParsedDuration
};
export default dateService;
