import React from 'react';
import { Button, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

import { SelectFavoriteModular, CloseDialogIcon } from '../common';
import { validationService } from '../../services';
import i18n from '../../i18n';

class AddFavoriteDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    addFavorite: PropTypes.func.isRequired,
  }

  state = {
    projectsForClient: [],
    chosenClientId: this.props.contacts[0] ? this.props.contacts[0].id : 0,
    chosenProjectId: 0,
    chosenActivityId: this.props.activities[0] ? this.props.activities[0].id : 0,
  };

  handleClientChange = event => {
    this.setState({
      chosenClientId: event.target.value,
      projectsForClient: this.props.projects.filter((p) => p.contact_id === event.target.value)
    })
  };

  handleProjectChange = event => {
    this.setState({ chosenProjectId: event.target.value })
  };

  handleActivityChange = event => {
    this.setState({ chosenActivityId: event.target.value })
  };

  submit() {
    const favJson = {
      clientId: this.state.chosenClientId,
      projectId: this.state.chosenProjectId,
      activityId: this.state.chosenActivityId,
    };

    if (validationService.newFavoriteIsValid(favJson)) {
      this.props.addFavorite(favJson);
      this.setState({
        chosenClientId: 0,
        chosenProjectId: 0,
        chosenActivityId: 0,
      });
      this.props.handleClose();
    }
  }

  render() {
    const { open, handleClose, contacts, activities } = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-favorite-dialog"
      >
        <DialogTitle id="add-favorite-dialog">
          {i18n.t('dialogs.add_favorite')}
          <CloseDialogIcon handleClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <SelectFavoriteModular
            contacts={contacts}
            projectsForClient={this.state.projectsForClient}
            activities={activities}
            chosenClientId={this.state.chosenClientId}
            chosenProjectId={this.state.chosenProjectId}
            chosenActivityId={this.state.chosenActivityId}
            handleClientChange={this.handleClientChange}
            handleProjectChange={this.handleProjectChange}
            handleActivityChange={this.handleActivityChange}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => this.submit()}
            color="primary"
            variant="contained"
          >
              {i18n.t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AddFavoriteDialog;
