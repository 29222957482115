import React from 'react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

const styles = theme => ({
  closeIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
})

function CloseDialogIcon(props) {
  return (
    <IconButton
      className={props.classes.closeIcon}
      onClick={() => props.handleClose()}
    >
      <Close fontSize='large' />
    </IconButton>
  )
}

CloseDialogIcon.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true})(CloseDialogIcon);
