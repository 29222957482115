import React, {useEffect, useMemo} from 'react';
import {Typography, LinearProgress, CircularProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from '../i18n';
import {getProjectBudgets} from '../actions';
import {CenteringGrid} from "./common";

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  itemContainer: {
    margin: '5px 10px 5px 10px',
    padding: '12px',
    border: '2px solid #eee',
    borderRadius: '5px',
  },
  progressBarRoot: {
    height: '12px',
    marginTop: '2px',
    marginBottom: '2px',
  },
  progressBarBudgetRoot: {
    height: '12px',
    marginTop: '2px',
    marginBottom: '2px',
    backgroundColor: 'white',
  },
  backgroundRed: {
    backgroundColor: 'red',
  },
  backgroundGrey: {
    backgroundColor: 'grey',
  }
})

const ProjectBudgetWidget = ({classes, contacts, projectBudgets, getProjectBudgets}) => {
  const budgetData = useMemo(() => projectBudgets.map(projectBudget => {
    const ist = Number(projectBudget.effektive_stunden);
    const plan = Number(projectBudget.budgetierte_stunden);
    const budgetUeberschritten = ist > plan;
    const prozentualIst = budgetUeberschritten ? 100 : ((ist / plan) * 100);
    const prozentualPlan = budgetUeberschritten ? ((plan / ist) * 100) : 100;

    return {
      id: projectBudget.projekt_id,
      effektiv: ist,
      budgetiert: plan,
      prozentualIst,
      prozentualPlan,
      budgetUeberschritten,
      projectName: projectBudget.projekt_name,
      customerName: contacts?.find(contact => contact.id === projectBudget.contact_id)?.name_1
    };
  }), [projectBudgets, contacts]);

  // sicherstellen das aktuell projekt daten vorhanden sind
  useEffect(() => {
    getProjectBudgets();
  }, [getProjectBudgets]);

  if (projectBudgets.length === 0) {
    return (
      <CenteringGrid>
        <CircularProgress/>
      </CenteringGrid>
    );
  }

  return (
    <div className={classes.root}>
      {budgetData.map(item => (
        <div key={item.id} className={classes.itemContainer}>
          <Typography>{`${i18n.t('project')}: ${item.projectName}`}</Typography>
          <Typography>{`${i18n.t('customer')}: ${item.customerName}`}</Typography>

          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Typography>{`${i18n.t('effektiv')}: ${item.effektiv}`}</Typography>
          </div>
          <LinearProgress
            classes={{determinate: classes.progressBarRoot, ...(item.budgetUeberschritten && {bar1Determinate: classes.backgroundRed})}}
            value={item.prozentualIst}
            variant="determinate"
          />
          <LinearProgress
            classes={{determinate: classes.progressBarBudgetRoot, bar1Determinate: classes.backgroundGrey}}
            value={item.prozentualPlan}
            variant="determinate"
          />
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Typography>{`${i18n.t('budgetiert')}: ${item.budgetiert}`}</Typography>
          </div>
        </div>
      ))}
    </div>
  )
}

ProjectBudgetWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectBudgets: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const {contacts, projectBudgets} = state.bexioData;
  return {contacts, projectBudgets};
};

export default connect(mapStateToProps, {getProjectBudgets})(withStyles(styles, {withTheme: true})(ProjectBudgetWidget));
