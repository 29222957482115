import React, { Component } from 'react';
import moment from 'moment';
import { Button, Dialog, Select, Input, MenuItem, InputLabel, FormControl, Grid,
  TextField, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { InlineDatePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { RteEditor, CloseDialogIcon, SelectFavoriteModular, SameColorSwitch } from '../common';
import { dateService, utilService, validationService, localStorageService } from '../../services';
import i18n from '../../i18n';

const styles = theme => ({
  gridContainer: {
    marginBottom: '15px',
  },
  gridContainerInner: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectFormControl: {
    width: '100%',
    backgroundColor: '#EEE',
    marginBottom: '15px',
  },
  selectRoot: {
    paddingLeft: '12px',
    marginTop: '4px',
  },
  actionsRoot: {
    justifyContent: 'space-between',
  },
  calendarIcon: {
    position: 'absolute',
    top: '27%',
    right: '5%',
    color: 'rgba(0, 0, 0, 0.42)',
  },
  durationContainer: {
    float: 'right',
    maxWidth: '120px',
  },
  durationInput: {
    backgroundColor: '#EEE',
  },
  dateFormControl: {
    maxWidth: '160px',
    marginTop: '16px',
    backgroundColor: '#EEE',
  },
  removeButton: {
    marginLeft: '40px',
    color: '#DC0000',
  }
})

class EditTimeTrackingDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isOwnData: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    favorites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientId: PropTypes.number.isRequired,
        projectId: PropTypes.number.isRequired,
        activityId: PropTypes.number.isRequired,
        client: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        activity: PropTypes.string.isRequired,
        combinedName: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    chosenTimeTracking: PropTypes.object,
    addTimeTracking: PropTypes.func.isRequired,
    editTimeTracking: PropTypes.func.isRequired,
    removeTimeTracking: PropTypes.func.isRequired,
  };

  state = this.getInitialState();

  getInitialState() {
    const initialFavorite = this.getInitialFavorite();

    return {
      useFavoriteSelect: this.props.isOwnData,
      text: '',
      textError: '',
      duration: '00:00',
      durationError: '',
      valid: false,
      chosenDate: moment(),
      timeTrackingIsLoaded: false,
      projectsForClient: this.props.projects.filter((p) => p.contact_id === initialFavorite.clientId),
      chosenFavorite: initialFavorite,
      chosenClientId: initialFavorite.clientId,
      chosenProjectId: initialFavorite.projectId,
      chosenActivityId: initialFavorite.activityId,
    }
  }

  getInitialFavorite() {
    return {
      id: -1,
      clientId: 0,
      projectId: 0,
      activityId: 0,
      client: '',
      project: '',
      activity: '',
      combinedName: ''
    }
  }

  resetState() {
    this.setState(this.getInitialState());
  }

  resetAndClose() {
    this.resetState();
    this.props.handleClose();
  }

  handleSwitchChange = newValue => {
    this.setState({ useFavoriteSelect: newValue});
  };

  handleFavoriteChange = event => {
    const matchedFavorite = this.props.favorites.find(f => f.id === event.target.value);
    this.setState({ chosenFavorite: matchedFavorite || this.getInitialFavorite()});
  };

  handleDurationChange = event => {
    this.setState({ duration: event.target.value});
  };

  handleDateChange = pickedDate => {
    this.setState({ chosenDate: pickedDate});
  };

  handleClientChange = event => {
    this.setState({
      chosenClientId: event.target.value,
      projectsForClient: this.props.projects.filter((p) => p.contact_id === event.target.value)
    });
  };

  handleProjectChange = event => {
    this.setState({ chosenProjectId: event.target.value });
  };

  handleActivityChange = event => {
    this.setState({ chosenActivityId: event.target.value });
  };

  getFavCombination(forState = this.state) {
    return {
      clientId: forState.chosenClientId,
      projectId: forState.chosenProjectId,
      activityId: forState.chosenActivityId,
    }
  }

  getEntry(forState, useExisting) {
    const { chosenDate, text, duration } = forState;
    const { chosenFavorite } = this.state;
    const usedCombination = useExisting ? chosenFavorite : this.getFavCombination(forState);

    return {
      ...this.props.chosenTimeTracking,
      clientId: usedCombination.clientId,
      projectId: usedCombination.projectId,
      activityId: usedCombination.activityId,
      text: text,
      duration: dateService.getParsedDuration(duration),
      date: chosenDate,
    };
  }

  save() {
    const { favorites } = this.props;
    const useExisting = this.state.useFavoriteSelect && this.state.chosenFavorite.id !== -1;

    if (useExisting && !validationService.favoriteIdIsValid(favorites, this.state.chosenFavorite.id)) {
      return;
    }

    if (!useExisting && !validationService.newFavoriteIsValid(this.getFavCombination())) {
      return;
    }

    const newState = validationService.validateStateForTimeTracking(this.state);
    this.setState(newState);
    if (newState.valid) {
      this.executeSaveAction(this.getEntry(newState, useExisting), this.props.chosenTimeTracking);
      this.resetAndClose();
    }
  }

  executeSaveAction(editedEntry, oldEntry) {
    if (oldEntry.isContinuation) {
      this.props.editTimeTracking(editedEntry, oldEntry.date);
      localStorageService.setRunningTimeTracking(null);
    } else if (oldEntry.running) {
      this.props.addTimeTracking(editedEntry);
      localStorageService.setRunningTimeTracking(null);
    } else {
      this.props.editTimeTracking(editedEntry, oldEntry.date);
    }
  }

  remove(wasRunning, id) {
    this.props.removeTimeTracking(wasRunning, id);
    wasRunning && localStorageService.setRunningTimeTracking(null);
    this.resetAndClose();
  }

  onDialogEnter() {
    const { chosenTimeTracking, favorites, projects } = this.props;

    if (Object.keys(chosenTimeTracking).length > 0) {
      const usedFavorite = utilService.getFavoriteForTimeTracking(chosenTimeTracking, favorites)
      || {
        ...this.getInitialFavorite(),
        clientId: chosenTimeTracking.contact_id,
        projectId: chosenTimeTracking.pr_project_id,
        activityId: chosenTimeTracking.client_service_id
      };

      this.setState({
        timeTrackingIsLoaded: true,
        text: chosenTimeTracking.text,
        duration: chosenTimeTracking.duration || '00:00',
        chosenFavorite: usedFavorite,
        chosenClientId: usedFavorite.clientId,
        chosenProjectId: usedFavorite.projectId,
        chosenActivityId: usedFavorite.activityId,
        projectsForClient: projects.filter((p) => p.contact_id === usedFavorite.clientId),
        chosenDate: moment(chosenTimeTracking.date)
      });
    }
  }

  getChooseFavJsx(isVacation) {
    const { favorites, classes, contacts, activities } = this.props;

    return (
      <>
        <Grid container direction="row" className={classes.gridContainer}>
          <Grid item xs={3} />
          <Grid item container direction="row" xs={6} className={classes.gridContainerInner}>
            <Button
              onClick={() => this.handleSwitchChange(false)}
              color={!this.state.useFavoriteSelect ? 'primary' : 'secondary'}
              variant="text"
            >
                {i18n.t('dialogs.custom')}
            </Button>
            <SameColorSwitch
              color="primary"
              checked={this.state.useFavoriteSelect}
              onChange={e => this.handleSwitchChange(e.target.checked)}
              name="edit-select-type"
            />
            <Button
              onClick={() => this.handleSwitchChange(true)}
              color={this.state.useFavoriteSelect ? 'primary' : 'secondary'}
              variant="text"
            >
                {i18n.t('dialogs.favorite')}
            </Button>
          </Grid>
          <Grid item xs={3} />
        </Grid>
        {!!this.state.useFavoriteSelect && (
          <FormControl className={classes.selectFormControl}>
            <InputLabel variant="filled" required htmlFor="edit-choose-favorite">
              {i18n.t('favorite')}
            </InputLabel>
            <Select
              disabled={isVacation}
              classes={{"root": classes.selectRoot}}
              value={this.state.chosenFavorite.id}
              onChange={this.handleFavoriteChange}
              input={<Input name="edit-choose-favorite" id="edit-choose-favorite" autoFocus/>}
            >
              <MenuItem value={-1}>{i18n.t('no_match')}</MenuItem>
              {favorites.map((f) => (
                <MenuItem value={f.id} key={f.id}>{f.combinedName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!this.state.useFavoriteSelect && (
          <SelectFavoriteModular
            isDisabled={isVacation}
            contacts={contacts}
            projectsForClient={this.state.projectsForClient}
            activities={activities}
            chosenClientId={this.state.chosenClientId}
            chosenProjectId={this.state.chosenProjectId}
            chosenActivityId={this.state.chosenActivityId}
            handleClientChange={this.handleClientChange}
            handleProjectChange={this.handleProjectChange}
            handleActivityChange={this.handleActivityChange}
          />
        )}
      </>
    );
  }

  render() {
    const { classes, open, chosenTimeTracking } = this.props;
    const { chosenDate } = this.state;
    const isVacation = chosenTimeTracking.client_service_id === 6;

    return (
      <Dialog
        open={open}
        onClose={() => this.resetAndClose()}
        onEnter={() => this.onDialogEnter()}
        aria-labelledby="edit-timetracking-dialog"
      >
        <DialogTitle id="edit-timetracking-dialog">
          {i18n.t('dialogs.edit_timetracking')}
          <CloseDialogIcon handleClose={() => this.resetAndClose()} />
        </DialogTitle>

        <DialogContent>
          {this.getChooseFavJsx(isVacation)}
          {this.state.timeTrackingIsLoaded &&
            <RteEditor
              disabled={isVacation}
              onChange={(htmlText) => this.setState({ text: htmlText })}
              initialValue={this.state.text}
            />
          }
          <FormControl className={classes.dateFormControl}>
            <CalendarToday classes={{root: classes.calendarIcon}} />
            <InputLabel variant="filled" shrink required>
              {i18n.t('date')}
            </InputLabel>
            <InlineDatePicker
              disabled={isVacation}
              isinputdatepicker="true"
              value={chosenDate}
              onChange={this.handleDateChange}
              format="DD.MM.YYYY"
            />
          </FormControl>
          <TextField
            required
            disabled={isVacation}
            id="edit-duration-text"
            label={i18n.t('time')}
            value={this.state.duration}
            onChange={this.handleDurationChange}
            error={this.state.durationError.length === 0 ? false : true }
            helperText={this.state.durationError}
            className={classes.durationContainer}
            inputProps={{className: classes.durationInput}}
            margin="normal"
            variant="filled"
          />
        </DialogContent>

        <DialogActions classes={{root: classes.actionsRoot}}>
          <Button className={classes.removeButton} onClick={() => this.remove(chosenTimeTracking.running, chosenTimeTracking.id)}>
            {i18n.t('remove')}
          </Button>
          <Button
            onClick={() => this.save()}
            disabled={isVacation}
            color="primary"
            variant="contained">
              {i18n.t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true})(EditTimeTrackingDialog);
