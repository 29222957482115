import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import EditTimeTrackingDialog from './EditTimeTrackingDialog';
import TimeTracking from './TimeTracking';

const styles = theme => ({
  root: {
    paddingTop: '10px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  list: {
    paddingTop: '10px',
  },
  clientTitle: {
    fontSize: '1.2rem',
    "text-transform": 'uppercase',
  },
})

class TimeTrackingWidget extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dayData: PropTypes.object.isRequired,
    isLoadingBexioData: PropTypes.bool.isRequired,
    isOwnData: PropTypes.bool.isRequired,
    dayIsCompleted: PropTypes.bool.isRequired,
    favorites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientId: PropTypes.number.isRequired,
        projectId: PropTypes.number.isRequired,
        activityId: PropTypes.number.isRequired,
        client: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        activity: PropTypes.string.isRequired,
        combinedName: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    addTimeTracking: PropTypes.func.isRequired,
    editTimeTracking: PropTypes.func.isRequired,
    removeTimeTracking: PropTypes.func.isRequired,
    continueTimeTracking: PropTypes.func.isRequired,
  };

  state = {
    showEditDialog: false,
    chosenTimeTracking: {},
  };

  toggleEditDialog = (forTimeTracking = this.state.chosenTimeTracking) => {
    this.setState({
      showEditDialog: !this.state.showEditDialog,
      chosenTimeTracking: {...forTimeTracking},
     });
  };

  render() {
    const { classes, isOwnData, dayData, favorites, addTimeTracking, editTimeTracking,
      removeTimeTracking, continueTimeTracking, dayIsCompleted, contacts, projects, activities } = this.props;

    // group timeTrackings by client in a new object
    const timeTrackingsByClient = {};
    const uniqueClients = [...new Set(dayData.timeTrackings.map((t) => t.contactName))];
    uniqueClients.forEach(client => {
      timeTrackingsByClient[client] = dayData.timeTrackings.filter(t => t.contactName === client);
    });

    return (
      <div className={classes.root}>
        <List className={classes.list}>
          {
            Object.keys(timeTrackingsByClient).map(client => (
              <Fragment key={client}>
                <ListItem>
                  <ListItemText primary={client} classes={{primary: classes.clientTitle}} />
                </ListItem>
                {timeTrackingsByClient[client].map((timeTracking) => (
                  <TimeTracking
                    key={timeTracking.id}
                    data={timeTracking}
                    isOwnData={isOwnData}
                    dayIsCompleted={dayIsCompleted}
                    continueTimeTracking={continueTimeTracking}
                    edit={this.toggleEditDialog}
                  />
                ))}
              </Fragment>
            ))
          }
        </List>

        <EditTimeTrackingDialog
          open={this.state.showEditDialog}
          isOwnData={isOwnData}
          handleClose={this.toggleEditDialog}
          contacts={contacts}
          projects={projects.filter(p => p.pr_state_id !== 3)}
          activities={activities}
          favorites={favorites}
          chosenTimeTracking={this.state.chosenTimeTracking}
          addTimeTracking={addTimeTracking}
          editTimeTracking={editTimeTracking}
          removeTimeTracking={removeTimeTracking}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true})(TimeTrackingWidget);
