import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { Close, Add } from '@material-ui/icons';
import { connect } from 'react-redux';

const styles = {
  fillRed: {
    fill: 'darkred',
  },
};

function Favorite(props) {
  const { project, activity } = props.data;

  return (
    <ListItem>
      <ListItemText
        primary={project}
        secondary={activity}
      />
      <ListItemSecondaryAction>
        {getActionBtn(props)}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

function getActionBtn(props) {
  if (props.editMode) {
    return (
      <IconButton onClick={() => props.showRemove(props.data)}>
        <Close classes={{root: props.classes.fillRed}} />
      </IconButton>
    );
  } else if (!props.dayIsCompleted || props.isAdmin) {
    return (
      <IconButton onClick={() => props.showAddTimeTracking(props.data)}>
        <Add />
      </IconButton>
    );
  }
}

Favorite.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    projectId: PropTypes.number.isRequired,
    activityId: PropTypes.number.isRequired,
    client: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    activity: PropTypes.string.isRequired,
    combinedName: PropTypes.string.isRequired
  }).isRequired,
  isOwnData: PropTypes.bool.isRequired,
  dayIsCompleted: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  showRemove: PropTypes.func.isRequired,
  showAddTimeTracking: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { isAdmin } = state.auth;
  return {
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Favorite));
