import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import FavoriteReducer from './FavoriteReducer';
import BexioDataReducer from './BexioDataReducer';

export default combineReducers({
  auth: AuthReducer,
  favorites: FavoriteReducer,
  bexioData: BexioDataReducer,
});
