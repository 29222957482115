import React from 'react';
import moment from 'moment';
import {
  Button,
  ButtonBase,
  Dialog,
  InputLabel,
  FormControl,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { CalendarToday, Close } from '@material-ui/icons';
import { InlineDatePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { addVacation } from '../../actions';
import { CloseDialogIcon } from '../common';
import { validationService } from '../../services';
import i18n from '../../i18n';

const styles = theme => ({
  calendarIcon: {
    zIndex: 13371337,
    position: 'absolute',
    top: '27%',
    right: '5%',
    color: 'rgba(0,0,0,0.42)',
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  marginTopBottom: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  dateFormControl: {
    maxWidth: '160px',
    marginTop: '16px',
    backgroundColor: '#EEE',
  },
  button: {
    ...theme.typography.h6,
    width: '160px',
    borderRadius: '10px',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  inactive: {
    backgroundColor: '#EEE',
    color: 'rgba(0,0,0,0.42)',
  },
  unsetEndIcon: {
    fill: 'darkred',
  },
});



class AddVacationDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    addVacation: PropTypes.func.isRequired,
  }

  state = this.getInitialState();

  getInitialState() {
    return {
      chosenStart: moment(),
      chosenEnd: null,
      isFullDay: true,
    }
  }

  getButtonStyle(isActive) {
    const colors = isActive ? this.props.classes.active : this.props.classes.inactive;
    return `${this.props.classes.button} ${colors}`;
  }

  handleStartChange = startDate => {
    if (validationService.vacationStartIsValid(startDate)) {
      this.setState({ chosenStart: startDate});
    }
  };

  handleEndChange = endDate => {
    const { isFullDay } = this.state;
    const endDateDay = endDate.startOf('day');
    if (validationService.vacationEndIsValid(this.state.chosenStart, endDateDay, isFullDay)) {
      this.setState({
        chosenEnd: endDateDay,
      });
    }
  };

  handleUnsetEnd = () => {
    this.setState({
      chosenEnd: null,
    });
  };

  handleFullHalfChange = () => {
    const { chosenStart, chosenEnd } = this.state;
    const newIsFullDayVal = !this.state.isFullDay;
    // if until date was chosen - it must still be valid, otherwise it is unset
    const newChosenEndVal = chosenEnd
      ? (validationService.vacationEndIsValid(chosenStart, chosenEnd, newIsFullDayVal)
        ? chosenEnd
        : null
      ) :null;

    this.setState({
      isFullDay: newIsFullDayVal,
      chosenEnd: newChosenEndVal
    });
  };

  submit() {
    const { chosenStart, chosenEnd, isFullDay } = this.state;

    if (chosenEnd === null && validationService.vacationStartIsValid(chosenStart)) {
      this.props.addVacation(chosenStart, isFullDay, chosenEnd);
      this.props.handleClose();
    } else if (validationService.vacationStartIsValid(chosenStart) &&
    validationService.vacationEndIsValid(chosenStart, chosenEnd, isFullDay)) {
      this.props.addVacation(chosenStart, isFullDay, chosenEnd);
      this.props.handleClose();
    }
  }

  render() {
    const { classes, dayData, open, handleClose } = this.props;
    const { chosenStart, chosenEnd, isFullDay } = this.state;
    return (
      <Dialog
        open={open}
        onEnter={() => this.setState({
          ...this.getInitialState(),
          chosenStart: dayData.date
        })}
        onClose={handleClose}
        aria-labelledby="add-vacation-dialog"
      >
        <DialogTitle id="add-vacation-dialog">
          {i18n.t('add_vacation')}
          <CloseDialogIcon handleClose={handleClose} />
        </DialogTitle>

        <DialogContent>
          <div className={classes.centerContainer}>
            <FormControl className={classes.dateFormControl}>
              <CalendarToday classes={{root: classes.calendarIcon}} />
              <InputLabel variant="filled" shrink required>
                {chosenEnd !== null ? i18n.t('from') : i18n.t('date')}
              </InputLabel>
              <InlineDatePicker
                isinputdatepicker="true"
                value={chosenStart}
                maxDate={chosenEnd ? chosenEnd.clone() : moment('2100-01-01')}
                onChange={this.handleStartChange}
                format="DD.MM.YYYY"
              />
            </FormControl>
            <FormControl className={classes.dateFormControl}>
              {chosenEnd === null && <CalendarToday classes={{root: classes.calendarIcon}} />}
              {!!chosenEnd && (
                <ButtonBase onClick={this.handleUnsetEnd} className={classes.calendarIcon}>
                  <Close classes={{root: classes.unsetEndIcon}} />
                </ButtonBase>
              )}
              <InputLabel variant="filled" shrink required>
                {i18n.t('until')}
              </InputLabel>
              <InlineDatePicker
                isinputdatepicker="true"
                value={chosenEnd}
                minDate={chosenStart.clone()}
                minDateMessage={i18n.t('validation.invalid_vacation_end')}
                onChange={this.handleEndChange}
                format="DD.MM.YYYY"
              />
            </FormControl>
          </div>
          <div className={`${classes.centerContainer} ${classes.marginTopBottom}`}>
            <ButtonBase
              onClick={this.handleFullHalfChange}
              disabled={isFullDay}
              className={this.getButtonStyle(isFullDay)}
            >
              {i18n.t('full_day')}
            </ButtonBase>
            <ButtonBase
              onClick={this.handleFullHalfChange}
              disabled={!isFullDay}
              className={this.getButtonStyle(!isFullDay)}
            >
              {i18n.t('half_day')}
            </ButtonBase>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => this.submit()}
            color="primary"
            variant="contained"
          >
            {i18n.t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { dayData } = state.bexioData;
  return { dayData };
};

export default connect(mapStateToProps, {addVacation})(withStyles(styles, { withTheme: true})(AddVacationDialog));