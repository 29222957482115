import api from '../Api';
import {
  SET_USER,
} from './types';

export const setFromAuthUser = (authUser, isAdmin) => {
  return async (dispatch) => {
    const response = await api.post('users', { params: { access_token: authUser.access_token } });
    if (response.status == 200) {
      const data = response.data;
      dispatch({ type: SET_USER, user: { userID: data.user_id, firstname: data.firstname, lastname: data.lastname, isAdmin: data.is_admin }});
    }
  }
};
