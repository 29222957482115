import React, { Component } from 'react';
import { Button, Typography, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import i18n from '../../i18n';

const styles = theme => ({
  actionsRoot: {
    justifyContent: 'space-between',
  },
  removeButton: {
    color: '#DC0000',
  },
  dialogText: {
    fontSize: '16px',
  }
})

class RemoveFavoriteDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    chosenFavorite: PropTypes.shape({
      id: PropTypes.number.isRequired,
      clientId: PropTypes.number.isRequired,
      projectId: PropTypes.number.isRequired,
      activityId: PropTypes.number.isRequired,
      client: PropTypes.string.isRequired,
      project: PropTypes.string.isRequired,
      activity: PropTypes.string.isRequired,
      combinedName: PropTypes.string.isRequired
    }).isRequired,
    removeFavorites: PropTypes.func.isRequired,
  };

  remove(favoriteId) {
    this.props.removeFavorites([favoriteId])
    this.props.handleClose();
  }

  render() {
    const { classes, open, chosenFavorite } = this.props;

    return (
      <Dialog
        open={open}
        onClose={() => this.props.handleClose()}
        aria-labelledby="remove-favorite-dialog"
      >
        <DialogContent>
          <Typography className={classes.dialogText}>
            <span>{`${i18n.t('customer')}: ${chosenFavorite.client}`}</span>
            <br/>
            <span>{`${i18n.t('project')}: ${chosenFavorite.project}`}</span>
            <br/>
            <span>{`${i18n.t('activity')}: ${chosenFavorite.activity}`}</span>
            <br/>
            <br/>
            <span>{i18n.t('dialogs.remove_favorite')}</span>
          </Typography>
        </DialogContent>

        <DialogActions classes={{root: classes.actionsRoot}}>
          <Button
            onClick={() => this.props.handleClose()}
          >
            {i18n.t('abort')}
          </Button>
          <Button
            className={classes.removeButton}
            onClick={() => this.remove(chosenFavorite.id)}
          >
            {i18n.t('remove')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true})(RemoveFavoriteDialog);