import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, AppBar, Toolbar } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { InlineDatePicker } from 'material-ui-pickers';

import { dateService } from '../../services';
import i18n from '../../i18n';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  dateToolbar: {
    boxShadow: 'none',
    margin: 'none'
  },
  selectDate: {
    margin: '0 5px',
  },
  selectDateIcon: {
    marginLeft: '5px'
  }
})

function CalendarBar(props) {
  const { classes, selectedDate, getWeekData } = props;
  let datePicker = null;

  return (
    <AppBar position="static" color="default" className={classes.dateToolbar}>
      <Toolbar>
        <InlineDatePicker
          style={{display: 'none'}}
          ispicker='true'
          value={selectedDate}
          onChange={getWeekData}
          format='DD.MM.YYYY'
          ref={node => {
            datePicker = node;
          }}
        />

        <Typography variant="h6" className={classes.grow}>
          {selectedDate.format("dddd")}
          <Button onClick={(e) => datePicker.open(e)} className={classes.selectDate}>
            {selectedDate.format("DD.MM.YYYY")}
            <CalendarToday className={classes.selectDateIcon} />
          </Button>
        </Typography>
        <Button
          variant='outlined'
          color={dateService.isToday(selectedDate) ? 'primary' : 'default'}
          onClick={() => getWeekData(moment().startOf('day'))}
        >
          {i18n.t('today')}
        </Button>
      </Toolbar>
    </AppBar>
  );
}

CalendarBar.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  getWeekData: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true})(CalendarBar);
