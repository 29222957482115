const DEFAULT_COMMENT_HEADER_REGEX_OLDER = /^([\s\S]*)Geleistet von (.*?)<\/p><\s*br\s*\/?\s*>/g;
const DEFAULT_COMMENT_HEADER_REGEX_OLD = /^([\s\S]*)Geleistet von (.*?)<\s*\/\s*p\s*>/g;
const DEFAULT_COMMENT_HEADER_REGEX = /^([\s\S]*)Geleistet von (.*?)<\s*br\s*\/?\s*>/g;

const bexioEncoding = {
  encodeTextForBexio,
  decodeTextFromBexio,
  getWithoutHeader,
};

function getCommentHeader(firstName, lastName) {
  return `Geleistet von ${firstName} ${lastName}<br>`;
}

// first version was: wrap in p tag + put br after
// second version was: only wrap in p tag
// current version is: only put br tag after
export function getWithoutHeader(text) {
  if (DEFAULT_COMMENT_HEADER_REGEX_OLDER.test(text)) {
    return text.replace(DEFAULT_COMMENT_HEADER_REGEX_OLDER, '');
  }

  if (DEFAULT_COMMENT_HEADER_REGEX_OLD.test(text)) {
    return text.replace(DEFAULT_COMMENT_HEADER_REGEX_OLD, '');
  }

  return text.replace(DEFAULT_COMMENT_HEADER_REGEX, '');
}

export function encodeTextForBexio(userInput, firstName, lastName) {
  // remove possibly existing header anyway
  const encodedInput = getWithoutHeader(userInput)
    .replace(/<\/p>\s*<p>/g, '<br/>')
    .replace(/<\/?p>/g, '')
    .replace(/(<br\/?>)+/g, '<br>');

  return `${getCommentHeader(firstName, lastName)}${encodedInput}`;
}

export function decodeTextFromBexio(text) {
  // remove the header added by getCommentHeader
  // it shall never be visible to the user
  const decodedText = getWithoutHeader(text)
    .replace(/((<script>)[\s\S]*(<\/script>))/g, '')
    .replace(/^/g, '<p>')
    .replace(/$/g, '</p>')
    .replace(/<\s*br\s*\/?\s*>/g, '</p><p>')
    .replace(/<ul>/g, '</p><ul>')
    .replace(/<\/ul>/g, '</ul><p>')
    .replace(/<ol>/g, '</p><ol>')
    .replace(/<\/ol>/g, '</ol><p>');

  return decodedText;
}

export default bexioEncoding;
