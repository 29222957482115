import React, { Component } from 'react';
import { Button, Dialog, Select, Input, MenuItem, InputLabel, Grid,
  FormControl, TextField, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { SelectFavoriteModular, RteEditor, CloseDialogIcon, SameColorSwitch } from '../common';
import { dateService, validationService } from '../../services';
import i18n from '../../i18n';

const styles = theme => ({
  gridContainer: {
    marginBottom: '15px',
  },
  gridContainerInner: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectFormControl: {
    width: '100%',
    backgroundColor: '#EEE',
    marginBottom: '10px',
  },
  selectRoot: {
    paddingLeft: '12px',
    marginTop: '4px',
  },
  durationContainer: {
    float: 'right',
    maxWidth: '120px',
  },
  durationInput: {
    backgroundColor: '#EEE',
  },
})

class AddTimeTrackingDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isOwnData: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    favorites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientId: PropTypes.number.isRequired,
        projectId: PropTypes.number.isRequired,
        activityId: PropTypes.number.isRequired,
        client: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        activity: PropTypes.string.isRequired,
        combinedName: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    chosenFavorite: PropTypes.shape({
      id: PropTypes.number.isRequired,
      clientId: PropTypes.number.isRequired,
      projectId: PropTypes.number.isRequired,
      activityId: PropTypes.number.isRequired,
      client: PropTypes.string.isRequired,
      project: PropTypes.string.isRequired,
      activity: PropTypes.string.isRequired,
      combinedName: PropTypes.string.isRequired
    }).isRequired,
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleFavoriteChange: PropTypes.func.isRequired,
    addTimeTracking: PropTypes.func.isRequired,
    startTimeTracking: PropTypes.func.isRequired,
  };

  state = this.getInitialState();

  getInitialState() {
    return {
      useFavoriteSelect: this.props.isOwnData,
      text: '',
      textError: '',
      duration: '00:00',
      durationError: '',
      valid: false,
      projectsForClient: this.props.projects.filter((p) => p.contact_id === this.props.chosenFavorite.clientId),
      chosenClientId: this.props.chosenFavorite.clientId,
      chosenProjectId: this.props.chosenFavorite.projectId,
      chosenActivityId: this.props.chosenFavorite.activityId,
    }
  }

  resetState() {
    this.setState(this.getInitialState());
  }

  resetAndClose() {
    this.resetState();
    this.props.handleClose();
  }

  handleSwitchChange = newValue => {
    this.setState({ useFavoriteSelect: newValue});
  };

  handleDurationChange = event => {
    this.setState({ duration: event.target.value});
  };

  handleClientChange = event => {
    this.setState({
      chosenClientId: event.target.value,
      projectsForClient: this.props.projects.filter((p) => p.contact_id === event.target.value)
    })
  };

  handleProjectChange = event => {
    this.setState({ chosenProjectId: event.target.value })
  };

  handleActivityChange = event => {
    this.setState({ chosenActivityId: event.target.value })
  };

  getEntry() {
    const { chosenClientId, chosenProjectId, chosenActivityId, text, duration } = this.state;

    // the underscore variants are required by util functions to resolve
    // the corresponding names, or to determine if a corresponding favorite exists
    const usedCombination = {
      contact_id: chosenClientId,
      pr_project_id: chosenProjectId,
      client_service_id: chosenActivityId,
      clientId: chosenClientId,
      projectId: chosenProjectId,
      activityId: chosenActivityId,
    };

    return {
      ...usedCombination,
      text: text,
      duration: dateService.getParsedDuration(duration),
    };
  }

  submit() {
    const { favorites, chosenFavorite } = this.props;
    const { useFavoriteSelect } = this.state;

    if (useFavoriteSelect && !validationService.favoriteIdIsValid(favorites, chosenFavorite.id)) {
      return;
    }

    if (validationService.canStartTimer(this.state.duration)) {
      this.props.startTimeTracking(this.getEntry());
      this.resetAndClose();
      return;
    }

    const newState = validationService.validateStateForTimeTracking(this.state);
    this.setState(newState);
    if (newState.valid) {
      this.props.addTimeTracking(this.getEntry());
      this.resetAndClose();
    }
  }

  getChooseFavJsx() {
    const { favorites, classes, handleFavoriteChange,
    chosenFavorite, contacts, activities } = this.props;

    return (
      <>
        <Grid container direction="row" className={classes.gridContainer}>
          <Grid item xs={3} />
          <Grid item container direction="row" xs={6} className={classes.gridContainerInner}>
            <Button
              onClick={() => this.handleSwitchChange(false)}
              color={!this.state.useFavoriteSelect ? 'primary' : 'secondary'}
              variant="text"
            >
                {i18n.t('dialogs.custom')}
            </Button>
            <SameColorSwitch
              color="primary"
              checked={this.state.useFavoriteSelect}
              onChange={e => this.handleSwitchChange(e.target.checked)}
              name="add-select-type"
            />
            <Button
              onClick={() => this.handleSwitchChange(true)}
              color={this.state.useFavoriteSelect ? 'primary' : 'secondary'}
              variant="text"
            >
                {i18n.t('dialogs.favorite')}
            </Button>
          </Grid>
          <Grid item xs={3} />
        </Grid>
        {!!this.state.useFavoriteSelect && (
          <FormControl className={classes.selectFormControl}>
            <InputLabel variant="filled" required htmlFor="add-choose-favorite">
              {i18n.t('favorite')}
            </InputLabel>
            <Select
              classes={{"root": classes.selectRoot}}
              value={chosenFavorite.id}
              onChange={handleFavoriteChange}
              input={<Input name="add-choose-favorite" id="add-choose-favorite" autoFocus />}
            >
              {favorites.map((f) => (
                <MenuItem value={f.id} key={f.id}>{f.combinedName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!this.state.useFavoriteSelect && (
          <SelectFavoriteModular
            contacts={contacts}
            projectsForClient={this.state.projectsForClient}
            activities={activities}
            chosenClientId={this.state.chosenClientId}
            chosenProjectId={this.state.chosenProjectId}
            chosenActivityId={this.state.chosenActivityId}
            handleClientChange={this.handleClientChange}
            handleProjectChange={this.handleProjectChange}
            handleActivityChange={this.handleActivityChange}
          />
        )}
      </>
    );
  }

  render() {
    const { open, classes } = this.props;
    const durationIsInitial = this.state.duration === '00:00';
    return (
      <Dialog
        open={open}
        onClose={() => this.resetAndClose()}
        onEnter={() => this.resetState()}
        aria-labelledby="add-timetracking-dialog"
      >
        <DialogTitle id="add-timetracking-dialog">
          {i18n.t('dialogs.add_timetracking')}
          <CloseDialogIcon handleClose={() => this.resetAndClose()} />
        </DialogTitle>

        <DialogContent>
          {this.getChooseFavJsx()}
          <RteEditor
            onChange={(htmlText) => this.setState({ text: htmlText })}
            initialValue={this.state.text}
          />
          <TextField
            required
            id="duration-text"
            label={i18n.t('time')}
            value={this.state.duration}
            onChange={this.handleDurationChange}
            error={this.state.durationError.length === 0 ? false : true }
            helperText={this.state.durationError}
            className={classes.durationContainer}
            inputProps={{className: classes.durationInput}}
            margin="normal"
            variant="filled"
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => this.submit()}
            color="primary"
            variant="contained">
              {durationIsInitial ? i18n.t('dialogs.start_timer') : i18n.t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true})(AddTimeTrackingDialog);
