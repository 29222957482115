import api from '../Api';
import i18n from '../i18n';
import {
  GET_FAVORITES,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_FAIL,
  ADD_FAVORITE,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAIL,
  REMOVE_FAVORITE,
  REMOVE_FAVORITE_SUCCESS,
  REMOVE_FAVORITE_FAIL,
} from './types';

export const getFavorites = () => {
  return async (dispatch, getState) => {
    dispatch({type: GET_FAVORITES});

    try {
      const response = await api.get('favoriten');

      if (!response.data.favoriten || response.status !== 200) {
        throw response;
      }

      dispatch({
        type: GET_FAVORITES_SUCCESS,
        favorites: response.data.favoriten
      });

      /* TODO ersetzen mit cleanup für favoriten von inaktiven projects 
              - neue action in favoriten controller machen, da projects mit ps_state_id=3 gar nie geladen werden!
              - button am besten wohl in favoriten widget anzeigen (wenn bearbeiten modus)
      */
      // remove favs whose project is not active anymore
      // if (response.data.favoriten.length > 0) {
      //   const activeProjectIds = getState().bexioData.projects.filter(p => p.pr_state_id !== 3).map(p => p.id);
      //   const outdatedFavIds = response.data.favoriten.filter(f => !activeProjectIds.includes(f.projectId)).map(f => f.id);
      //   if (outdatedFavIds.length > 0) {
      //     await removeFavorites(outdatedFavIds)(dispatch, getState);
      //   }
      // }
    } catch (e) {
      dispatch({
        type: GET_FAVORITES_FAIL,
        error: i18n.t('errors.get_favorites')
      });
    }
  }
}

// favJson contains only the 3 ids of the combination
export const addFavorite = (favJson) => {
  return async (dispatch, getState) => {
    dispatch({type: ADD_FAVORITE});

    const bexioData = getState().bexioData;
    const clientName = bexioData.contacts.find(c => c.id === favJson.clientId).name_1;
    const projectName = bexioData.projects.find(p => p.id === favJson.projectId).name;
    const activityName = bexioData.activities.find(a => a.id === favJson.activityId).name;

    const newFavorite = {
      ...favJson,
      client: clientName,
      project: projectName,
      activity: activityName,
      combinedName: `${clientName} - ${projectName} - ${activityName}`
    }

    /*
    TODO ablauf korrekt machen. aber was ist den falsch? ->
    - im state werden der favorit erstellt, bevor serverseitig erstellt wird... -> falls der api call failed, ist der favorit im state da, aber nach page reload wieder weg.
    - action method muss POST sein (damit RESTful) -> PATCH ist nur für update
    */
    dispatch({
      type: ADD_FAVORITE_SUCCESS,
      newFavorite
    });

    try {
      const payload = {"favoriten": getState().favorites.favorites}; // state explizit neu laden, damit gerade erstellter favorit auch mit drin ist... DOH!
      const result = await api.patch('favoriten', payload);
      if (result.status !== 200) {
        throw result;
      }
    } catch (e) {
      dispatch({
        type: ADD_FAVORITE_FAIL,
        error: i18n.t('errors.add_favorite')
      });
    }
  }
}

export const removeFavorites = (favIds) => {
  return async (dispatch, getState) => {
    dispatch({type: REMOVE_FAVORITE});

    /*
    TODO ablauf korrekt machen. aber was ist den falsch? ->
    - im state werden die favoriten entfernt, bevor sie serverseitig entfernt werden... -> falls der api call failed, ist der favorit nur im state entfernt, d.h. nach page reload ist der favorit wieder da.
    - action method muss DELETE sein (damit RESTful) -> PATCH ist nur für update
    */
    favIds.forEach(favId => {
      dispatch({
        type: REMOVE_FAVORITE_SUCCESS,
        id: favId
      });
    });

    try {
      const payload = {"favoriten": getState().favorites.favorites};
      const result = await api.patch('favoriten', payload);
      if (result.status !== 200) {
        throw result;
      }
    } catch (e) {
      dispatch({
        type: REMOVE_FAVORITE_FAIL,
        error: i18n.t('errors.remove_favorite')
      });
    }
  }
}
