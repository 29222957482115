import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';
import { CLEAR_ERRORS } from './actions/types';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(thunk)));

function errorListener() {
  let { auth, bexioData, favorites } = store.getState();
  const all_errors = [];
  if (auth.error) {
    all_errors.push(auth.error);
  }
  if (bexioData.errors) {
    all_errors.push(Object.values(bexioData.errors));
  }
  if (favorites.errors) {
    all_errors.push(Object.values(favorites.errors));
  }
  if (all_errors.some(e => e.length > 0)) {
    // alert(all_errors.filter(e => e.length > 0).join('\n'));
    store.dispatch({ type: CLEAR_ERRORS });
  }
}

store.subscribe(errorListener);

export default store;
