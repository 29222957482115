import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {AppBar, Toolbar, Menu, Typography, MenuItem, IconButton, Button} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import {utilService} from '../../services';
import {useAuth} from "react-oidc-context";
import {getUserData, getWeekData} from '../../actions';

const styles = {
  root: {
    flexGrow: 0,
  },
  logoImg: {
    height: 30,
    margin: 4,
  },
  noBoxShadow: {
    boxShadow: 'none'
  },
  fillSpace: {
    flexGrow: 1,
  },
  chosenUser: {
    color: 'white',
  }
};

const MainAppBar = ({classes, users, currentUser, dayData, getUserData, getWeekData}) => {
  const auth = useAuth();
  const [anchor, setAnchor] = useState(null);
  const [chosenUserId, setChosenUserId] = useState(currentUser.userID);
  const open = Boolean(anchor);
  const chosenUser = users.find(u => u.id === chosenUserId);
  const chosenUserName = chosenUser ? `${chosenUser.firstname} ${chosenUser.lastname}` : '';

  const handleMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const selectUser = async user => {
    const selectedId = user.target.value;
    // load timetrackings for chosen user
    await getUserData(selectedId);
    // force reload for current week
    getWeekData(dayData.date, true);

    setChosenUserId(selectedId);
    handleClose();
  };


  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="primary"
        classes={{
          root: classes.noBoxShadow,
        }}
      >
        <Toolbar>
          <img src="/oxon-logo.png" alt="Oxon Logo" className={classes.logoImg}/>
          <div className={classes.fillSpace}></div>
          {auth.isAuthenticated && (
            <>
              <Button color="primary" variant="contained" onClick={auth.removeUser}>Logout</Button>
              &nbsp;&nbsp;
            </>
          )}

          <Typography variant="h6" className={classes.chosenUser}>
            {chosenUserName}
          </Typography>
          {currentUser.isAdmin && (
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
          )}
          <Menu
            id="menu-appbar"
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            {users.map(u =>
              <MenuItem
                selected={chosenUserId === u.id}
                onClick={selectUser}
                value={u.id}
                key={u.id}
              >
                {`${u.firstname} ${u.lastname}`}
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  dayData: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
  getWeekData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {users, dayData} = state.bexioData;
  const currentUser = state.auth;

  const validUsers = users.filter(u => u.firstname);
  validUsers.sort((a, b) => utilService.sortByString(a.firstname, b.firstname));

  return {users: validUsers, currentUser, dayData};
};

export default connect(mapStateToProps, {getUserData, getWeekData})(withStyles(styles)(MainAppBar));
