import moment from 'moment';

import dateService from './date';
import i18n from '../i18n';

const utilService = {
  clearError,
  getSimpleTextFromHtml,
  resolveTimeTracking,
  addDayStatistics,
  getUsedVacationOnDate,
  getVacationStatistics,
  getFavoriteForTimeTracking,
  sortByString
};

function clearError(errorKey, oldErrors) {
  const newErrors = {...oldErrors};
  const errorKeyPartsNum = errorKey.split('_').length;
  const errorKeyBase = errorKey.split('_').splice(0, errorKeyPartsNum-1).join('_');
  delete newErrors[`${errorKeyBase}_fail`];
  return newErrors;
}

function getSimpleTextFromHtml(htmlText) {
  // get rid of html entities
  const htmlParser = new DOMParser();
  return htmlParser.parseFromString(htmlText, 'text/html').children[0].innerText;
}

function resolveTimeTracking(timeTracking, bexioData) {
  const project = bexioData.projects.find(p => p.id === timeTracking.pr_project_id);
  const contact = bexioData.contacts.find(c => c.id === timeTracking.contact_id);
  const activity = bexioData.activities.find(a => a.id === timeTracking.client_service_id);

  return {
    ...timeTracking,
    projectName: project ? project.name : i18n.t('no_match'),
    contactName: contact ? contact.name_1 : i18n.t('no_match'),
    activityName: activity ? activity.name : i18n.t('no_match'),
    text: timeTracking.text
  };
}

// use: calculates and adds day statistics to a day object
// parameters: day object
// returns: day object with day statistics
function addDayStatistics(dayData) {
  dayData.workDoneMinutes = dateService.getMinutesFromDurations(dayData.timeTrackings.map(t => t.duration || '00:00'));
  dayData.workDoneDuration = dateService.getDurationFromMinutes(dayData.workDoneMinutes);
  dayData.workDoneIndustrial = dayData.workDoneMinutes / 60;
  dayData.targetTimeIndustrial = dayData.targetMinutes / 60;
  dayData.difference = (dayData.workDoneMinutes - dayData.targetMinutes) / 60;
  return dayData;
}

function getUsedVacationOnDate(vacationTaken, onDate) {
  if (vacationTaken[onDate.get('year')] === undefined) {
    return 0;
  }
  const match = vacationTaken[onDate.get('year')][onDate.format("YYYY-MM-DD")];
  return match ? match.amount : 0;
}

function getVacationStatistics(untilDate, bexioData) {
  const { users, userIdForActiveData } = bexioData;
  const { vacationTaken, yearInitialBalances } = users.find(u => u.id === userIdForActiveData).vacationData;
  const yearInitialBalance = yearInitialBalances[untilDate.get('year')];
  let vacationBalanceUsed = 0;
  let vacationBalanceLeft = yearInitialBalance;

  for (let startDate = moment(untilDate).startOf('year');
    startDate.diff(untilDate, 'days') <= 0;
    startDate.add(1, 'days')
  ) {
    vacationBalanceUsed += getUsedVacationOnDate(vacationTaken, startDate);
    vacationBalanceLeft = yearInitialBalance - vacationBalanceUsed;
  }

  return {
    yearInitialBalance,
    vacationBalanceUsed,
    vacationBalanceLeft
  };
}

// use: check for a corresponding favorite, needed when editing a timetracking
// parameters: any timeTracking and a list of favorites
// returns: id of the favorite that matches the timeTracking, or -1 if none
function getFavoriteForTimeTracking(timeTracking, favorites) {
  const matchingFavorite = favorites.find(f =>
    f.clientId === timeTracking.contact_id &&
    f.projectId === timeTracking.pr_project_id &&
    f.activityId === timeTracking.client_service_id
  );
  return matchingFavorite;
}

function sortByString(a, b) {
  const normalize = str => str.toLowerCase()
    .replace('ä', 'a').replace('ö', 'o').replace('ü', 'u');

  const x = normalize(a);
  const y = normalize(b);
  return x < y ? -1 : x > y ? 1 : 0;
};

export default utilService;
