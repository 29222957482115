import React, {Component, Fragment, useEffect} from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  getAllBexioData, getWeekData, getDayData, startTimeTracking, continueTimeTracking,
  addTimeTracking, editTimeTracking, removeTimeTracking, getFavorites, addFavorite, removeFavorites
} from '../actions';
import FavoritenBudgetsTabs from '../components/FavoritenBudgetsTabs';
import FavoriteWidget from '../components/Favorites/FavoriteWidget';
import ProjectBudgetWidget from '../components/ProjectBudgetWidget';
import DateWidget from '../components/TimeTracking/DateWidget';
import TimeTrackingWidget from '../components/TimeTracking/TimeTrackingWidget';
import SummaryWidget from '../components/Summary/SummaryWidget';
import {CenteringGrid} from '../components/common';
import {utilService} from '../services';

const styles = {
  gridContainer: {
    height: 'calc(100% - 64px)'
  },
  favoriteWidget: {
    display: 'flex',
    height: '100%'
  },
  summaryWidget: {
    display: 'flex',
    height: '100%'
  },
  middleItem: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    "box-shadow": "0px 4px 4px 2px rgba(0,0,0,0.2)",
  },
};

const ScreensDashboard = ({classes, getWeekData, getDayData, weekData, dayData, isOwnData, isLoadingBexioData, ...props}) => {

  useEffect(() => {
    const load = async () => {
      await props.getAllBexioData();
      // load data for current week once bexio data is available
      getWeekData(moment().startOf('day'));

      await props.getFavorites();
    }

    load();
  }, []);

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      <Grid item xs={3} className={classes.favoriteWidget}>
        <FavoritenBudgetsTabs
          favoritenJsx={(
            <FavoriteWidget
              isOwnData={isOwnData}
              dayIsCompleted={dayData.isCompleted}
              getFavorites={props.getFavorites}
              addFavorite={props.addFavorite}
              startTimeTracking={props.startTimeTracking}
              removeFavorites={props.removeFavorites}
              addTimeTracking={props.addTimeTracking}
              contacts={props.contacts}
              projects={props.projects}
              activities={props.activities}
              favorites={props.favorites}
              isLoadingFavorites={props.isLoadingFavorites}
              isLoadingBexioData={isLoadingBexioData}
            />
          )}
          budgetsJsx={<ProjectBudgetWidget/>}
        />
      </Grid>
      <Grid item xs={6} className={classes.middleItem}>
        {isLoadingBexioData ? (
          <CenteringGrid>
            <CircularProgress/>
          </CenteringGrid>
        ) : (
          <Fragment>
            <DateWidget
              getWeekData={getWeekData}
              getDayData={getDayData}
              weekData={weekData}
              selectedDate={dayData.date}
            />
            <TimeTrackingWidget
              dayData={dayData}
              continueTimeTracking={props.continueTimeTracking}
              addTimeTracking={props.addTimeTracking}
              editTimeTracking={props.editTimeTracking}
              removeTimeTracking={props.removeTimeTracking}
              isOwnData={isOwnData}
              dayIsCompleted={dayData.isCompleted}
              contacts={props.contacts}
              projects={props.projects}
              activities={props.activities}
              favorites={props.favorites}
              isLoadingBexioData={isLoadingBexioData}
            />
          </Fragment>
        )}
      </Grid>
      <Grid item xs={3} className={classes.summaryWidget}>
        <SummaryWidget
          dayData={dayData}
          isLoadingBexioData={isLoadingBexioData}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  const {weekData, dayData, userIdForActiveData, contacts, projects, activities} = state.bexioData;
  const {favorites} = state.favorites;
  const {userID} = state.auth;

  contacts.sort((a, b) => utilService.sortByString(a.name_1, b.name_1));
  projects.sort((a, b) => utilService.sortByString(a.name, b.name));
  activities.sort((a, b) => utilService.sortByString(a.name, b.name));
  favorites.sort((a, b) => utilService.sortByString(a.combinedName, b.combinedName));

  return {
    weekData,
    dayData,
    isOwnData: userIdForActiveData === userID,
    contacts,
    projects,
    activities,
    favorites,
    isLoadingBexioData: state.bexioData.loading,
    isLoadingFavorites: state.favorites.loading,
  };
};

const mapDispatchToProps = {
  getAllBexioData,
  getWeekData,
  getDayData,
  startTimeTracking,
  continueTimeTracking,
  addTimeTracking,
  editTimeTracking,
  removeTimeTracking,
  getFavorites,
  addFavorite,
  removeFavorites
};

ScreensDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  getAllBexioData: PropTypes.func.isRequired,
  getWeekData: PropTypes.func.isRequired,
  getDayData: PropTypes.func.isRequired,
  startTimeTracking: PropTypes.func.isRequired,
  continueTimeTracking: PropTypes.func.isRequired,
  addTimeTracking: PropTypes.func.isRequired,
  editTimeTracking: PropTypes.func.isRequired,
  removeTimeTracking: PropTypes.func.isRequired,
  getFavorites: PropTypes.func.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorites: PropTypes.func.isRequired,
  weekData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dayData: PropTypes.object.isRequired,
  isOwnData: PropTypes.bool.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  favorites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      clientId: PropTypes.number.isRequired,
      projectId: PropTypes.number.isRequired,
      activityId: PropTypes.number.isRequired,
      client: PropTypes.string.isRequired,
      project: PropTypes.string.isRequired,
      activity: PropTypes.string.isRequired,
      combinedName: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  isLoadingBexioData: PropTypes.bool.isRequired,
  isLoadingFavorites: PropTypes.bool.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScreensDashboard));
