import React, {Component} from 'react';
import RichTextEditor from 'react-rte';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { bexioEncoding } from '../../services';

const styles = theme => ({
  root: {
    fontFamily: 'system-ui',
    backgroundColor: '#eee',
    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
    '&:hover': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
    },
  },
  removeMargin: {
    margin: 0,
  },
})

// remove margin from p tags => makes it look like br tags are used
function myBlockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  if (type === 'unstyled') {
    return 'removeMargin';
  }
}

class RteEditor extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    onChange: PropTypes.func.isRequired
  };

  state = {
    value: RichTextEditor.createValueFromString(
      bexioEncoding.decodeTextFromBexio(this.props.initialValue || ''),
      'html'
    )
  }

  onChange = (value) => {
    this.setState({value});
    this.props.onChange(
      value.toString('html')
    );
  };

  render () {
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD'},
        {label: 'Italic', style: 'ITALIC'}
      ],
      BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
      ]
    };

    return (
      <RichTextEditor
        className={this.props.classes.root}
        blockStyleFn={myBlockStyleFn}
        value={this.state.value}
        onChange={this.onChange}
        toolbarConfig={toolbarConfig}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true})(RteEditor)