import { createMuiTheme } from '@material-ui/core/styles';

const mainColor = '#1C9688';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: '#000000',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        width: '65%',
      },
    },
    MuiDialogTitle: {
      root: {
        position: 'relative',
        backgroundColor: mainColor,
        padding: '15px 18px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '15px 18px',
      },
    },
    MuiDialogActions: {
      root: {
        height: '60px',
        backgroundColor: '#EEEEEE',
        margin: 0,
        padding: '0 18px'
      },
    },
    MuiFilledInput: {
      underline: {
        '&:before': {
          borderBottomWidth: '2px',
        },
        '&:hover:before': {
          borderBottomWidth: '2px !important',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomWidth: '2px',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'inherit',
          backgroundColor: 'inherit',
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: '0 !important',
      },
      labelContainer: {
        padding: '6px 10px !important',
      }
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-around !important',
      }
    },
    MuiListItem: {
      container: {
       fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      },
      root: {
        paddingTop: '6px',
        paddingBottom: '6px',
      }
    }
  },
  props: {
    MuiDialog: {
      disableBackdropClick: true,
      disableEscapeKeyDown: true,
    },
  },
});

export default theme;
