import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { AccessTime, Lock, LockOpen } from '@material-ui/icons';
import { connect } from 'react-redux';
import moment from 'moment';

import i18n from '../../i18n';
import { dateService, validationService, bexioEncoding } from '../../services';

const styles = theme => {
  return ({
    root: {
      position: 'relative',
      'border-bottom': '2px solid #eee',
      padding: '5px 10px',
    },
    editButton: {
      display: 'flex',
      width: '100%',
      justifyContent: 'start',
      textAlign: 'left',
    },
    textRoot: {
      maxWidth: '70%',
    },
    rightArea: {
      right: '5%',
    },
    duration: {
      fontSize: 16,
      textAlign: 'center',
      fontWeight: 'bold',
      marginRight: '20px',
    },
    startButton: {
      padding: '4px 6px',
      border: '2px solid #cdcdcd',
    },
    timerIconStart: {
      marginRight: theme.spacing.unit,
    },
    timerIconStop: {
      marginRight: theme.spacing.unit,
      fill: 'white',
    },
    idSpan: {
      ...theme.typography.body1
    },
    htmlDiv: {
      ...theme.typography.body2,
      color: 'rgba(0, 0, 0, 0.54)',
      overflowWrap: 'break-word',
    },
  })
}

class TimeTracking extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    isOwnData: PropTypes.bool.isRequired,
    dayIsCompleted: PropTypes.bool.isRequired,
    continueTimeTracking: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const secondsOfDuration = dateService.getMinutesFromDurations([props.data.duration]) * 60;
    const elapsedSeconds = props.data.running ?
      (moment().unix() - props.data.startedAtUnixTimestamp) + secondsOfDuration : secondsOfDuration;
    const durationNow = dateService.getDurationFromMinutes(
      Math.round(elapsedSeconds / 60)
    );

    this.state = {
      elapsedSeconds,
      durationNow
    };
  }

  componentWillUnmount() {
    clearInterval(this.timerHandle);
  }

  getEntry() {
    return {
      ...this.props.data,
      clientId: this.props.data.contact_id,
      projectId: this.props.data.pr_project_id,
      activityId: this.props.data.client_service_id,
      duration: this.state.durationNow
    };
  }

  getActionBtn() {
    const { classes, dayIsCompleted, edit, continueTimeTracking, isAdmin } = this.props;
    const { running } = this.props.data;

    if (running) {
      return (
        <Button
          onClick={() => edit(this.getEntry())}
          color="primary"
          variant="contained"
        >
          <AccessTime fontSize={'small'} className={classes.timerIconStop} />
          {i18n.t('stop')}
        </Button>
      )
    } else if (dayIsCompleted) {
      if (isAdmin) {
        return <LockOpen fontSize={'small'} />;
      }
      return <Lock fontSize={'small'} />
    } else if (validationService.canContinueTimer()) {
      return (
        <Button
          className={classes.startButton}
          onClick={() => continueTimeTracking(this.getEntry())}
        >
          <AccessTime fontSize={'small'} className={classes.timerIconStart} />
          {i18n.t('start')}
        </Button>
      )
    }
  }

  render() {
    const { projectName, activityName, text, running } = this.props.data;
    const { classes, edit, data, dayIsCompleted, isAdmin } = this.props;
    const identifier = `${projectName} - ${activityName}`;

    if (!this.timerHandle && running) {
      this.timerHandle = setInterval(() => {
        const newElapsedSeconds = this.state.elapsedSeconds + 1;
        const newDuration = dateService.getDurationFromMinutes(
          Math.round(newElapsedSeconds / 60)
        );

        this.setState({
          elapsedSeconds: newElapsedSeconds,
          durationNow: newDuration
        });
      }, 1000);
    }

    return (
      <ListItem
        button
        onClick={() => edit(data)}
        disabled={running || (dayIsCompleted && !isAdmin)}
      >
        <ListItemText
          classes={{root: classes.textRoot}}
          disableTypography
          primary={<span className={classes.idSpan}>{identifier}</span>}
          secondary={
            <div
              className={classes.htmlDiv}
              dangerouslySetInnerHTML={{ __html: bexioEncoding.getWithoutHeader(text) || i18n.t('empty_description')}}
            >
            </div>
          }
        />

        <ListItemSecondaryAction classes={{root: classes.rightArea}}>
          <span className={classes.duration}>
            {this.state.durationNow}
          </span>
          {this.getActionBtn()}
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

const mapStateToProps = (state) => {
  const { isAdmin } = state.auth;
  return {
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true})(TimeTracking));
