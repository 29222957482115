import moment from 'moment';

import api from '../Api';
import i18n from '../i18n';
import {
  utilService,
  dateService,
  localStorageService,
  validationService,
  bexioEncoding
} from '../services';
import {
  GET_TIMETRACKINGS,
  GET_TIMETRACKINGS_SUCCESS,
  GET_TIMETRACKINGS_FAIL,
  GET_WORKLOAD_HISTORY,
  GET_WORKLOAD_HISTORY_SUCCESS,
  GET_WORKLOAD_HISTORY_FAIL,
  GET_PROJECT_BUDGETS,
  GET_PROJECT_BUDGETS_SUCCESS,
  GET_PROJECT_BUDGETS_FAIL,
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_VACATION_DATA,
  GET_USER_VACATION_DATA_SUCCESS,
  GET_USER_VACATION_DATA_FAIL,
  SET_USER_VACATION_DATA,
  SET_USER_VACATION_DATA_SUCCESS,
  SET_USER_VACATION_DATA_FAIL,
  GET_WEEK_DATA,
  GET_WEEK_DATA_SUCCESS,
  GET_WEEK_DATA_FAIL,
  GET_DAY_DATA,
  GET_DAY_DATA_SUCCESS,
  GET_DAY_DATA_FAIL,
  ADD_VACATION,
  ADD_VACATION_SUCCESS,
  ADD_VACATION_FAIL,
  ADD_TIMETRACKING,
  ADD_TIMETRACKING_SUCCESS,
  ADD_TIMETRACKING_FAIL,
  REMOVE_TIMETRACKING,
  REMOVE_TIMETRACKING_SUCCESS,
  REMOVE_TIMETRACKING_FAIL,
  EDIT_TIMETRACKING,
  EDIT_TIMETRACKING_SUCCESS,
  EDIT_TIMETRACKING_FAIL,
  START_TIMETRACKING,
  START_TIMETRACKING_SUCCESS,
  START_TIMETRACKING_FAIL,
  CONTINUE_TIMETRACKING,
  CONTINUE_TIMETRACKING_SUCCESS,
  CONTINUE_TIMETRACKING_FAIL,
} from './types';

export const getTimeTrackings = (userId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_TIMETRACKINGS });
    const isOwnData = userId === getState().auth.userID;

    try {
      const response = await api.post('bexio_call', {
        method: 'post',
        api_call: 'timesheet/search?order_by=date_desc&limit=2000',
        payload: [
          {
            "field": "user_id",
            "value": userId,
            "criteria":"="
          }
        ]
      });

      if (!response.data || response.status !== 200) {
        throw response;
      }

      // filter again, because bexio's filter isn't exact match
      // searching for an id of 6 would also match 16, 64, 162 etc.
      const timeTrackingsForUser = response.data.filter(t => t.user_id === userId);

      if (isOwnData && !!localStorageService.getRunningTimeTracking()) {
        const persistedTimeTracking = localStorageService.getRunningTimeTracking();
        const baseEntryIndex = timeTrackingsForUser.findIndex(t => t.id === persistedTimeTracking.id);

        // if an index was found, this means a existing timetracking is being continued
        if (baseEntryIndex !== -1) {
          timeTrackingsForUser[baseEntryIndex].running = true;
          timeTrackingsForUser[baseEntryIndex].isContinuation = persistedTimeTracking.isContinuation;
          timeTrackingsForUser[baseEntryIndex].startedAtUnixTimestamp = persistedTimeTracking.startedAtUnixTimestamp;
        } else {
          // the user has locally started a timer
          // add the started (not yet in bexio) timetracking to the store as well
          timeTrackingsForUser.push(persistedTimeTracking);
        }
      }

      const timeTrackingsByDate = {};
      const uniqueDates = [...new Set(timeTrackingsForUser.map(t => t.date))];
      uniqueDates.forEach(date => {
        timeTrackingsByDate[date] = timeTrackingsForUser.filter(t => t.date === date);
      });

      dispatch({
        type: GET_TIMETRACKINGS_SUCCESS,
        timeTrackings: timeTrackingsByDate,
        userIdForActiveData: userId
      });
    } catch (e) {
      dispatch({
        type: GET_TIMETRACKINGS_FAIL,
        error: i18n.t('errors.get_timetrackings')
      });
    }
  }
}

const getWorkloadHistory = (userId, onYearOfDate) => {
  return async (dispatch) => {
    dispatch({ type: GET_WORKLOAD_HISTORY });

    try {
      const startISO8601 = moment(onYearOfDate).startOf("year").format("YYYY-MM-DD");
      const endISO8601 = moment(onYearOfDate).endOf("year").format("YYYY-MM-DD");
      const targetHoursParams = {
        params: {
          von: startISO8601,
          bis: endISO8601
        }
      }

      const yearInformationResult = await api.get('arbeitsstunden', targetHoursParams);
      if (!yearInformationResult.data || yearInformationResult.status !== 200) {
        throw yearInformationResult;
      }

      let yearInformation = yearInformationResult.data;
      // every day must be present, otherwise use default
      if (!validationService.yearInformationIsValid(yearInformation)) {
        for (let startDate = onYearOfDate.clone().startOf('year');
          startDate.diff(onYearOfDate.endOf('year'), 'days') <= 0;
          startDate.add(1, 'days')
        ) {
          const isWeekend = startDate.day() === 0 || startDate.day() === 6;
          yearInformation.push({
            abgeschlossen: true,
            arbeitsstunden: isWeekend ? 0 : 8.4,
            datum: startDate.format('YYYY-MM-DD')
          });
        }
      }

      const userWorkloadsResult = await api.get(`arbeitspensum/${userId}`);
      const workloadsOfUser = userWorkloadsResult.data
      && userWorkloadsResult.data.length > 0 ?
      userWorkloadsResult.data :
      [{
        user_id: userId,
        von: startISO8601,
        bis: endISO8601,
        prozent: 100,
      }];

      const workloadHistory = {};
      yearInformation.forEach(day => {
        const dayDate = moment(day.datum);
        const activeWorkload = workloadsOfUser.find(w => {
          const vonDate = moment(w.von).startOf('day');
          const bisDate = moment(w.bis).startOf('day');


          return w.von && vonDate.isSameOrBefore(dayDate) &&
          (w.bis === null || bisDate.isSameOrAfter(dayDate))
        }) || workloadsOfUser[0];

        const targetMinutes = (day.arbeitsstunden * 60) * (activeWorkload.prozent / 100);

        // day.datum is returned as ISO8601
        workloadHistory[day.datum] = {
          baseTargetMinutes: day.arbeitsstunden * 60,
          targetMinutes,
          isCompleted: day.abgeschlossen,
          workload: activeWorkload.prozent
        }
      });

      dispatch({
        type: GET_WORKLOAD_HISTORY_SUCCESS,
        workloadHistory
      });
    } catch (e) {
      dispatch({
        type: GET_WORKLOAD_HISTORY_FAIL,
        error: i18n.t('errors.get_workload_history')
      });
    }
  }
}

export const getProjectBudgets = (userId, onYearOfDate) => {
  return async (dispatch) => {
    dispatch({ type: GET_PROJECT_BUDGETS });

    try {
      const projectbudgetsResult = await api.get('projektbudgets');

      if (!projectbudgetsResult.data || projectbudgetsResult.status !== 200) {
        throw projectbudgetsResult;
      }

      dispatch({
        type: GET_PROJECT_BUDGETS_SUCCESS,
        budgetData: projectbudgetsResult.data,
      });
    } catch (e) {
      dispatch({
        type: GET_PROJECT_BUDGETS_FAIL,
        error: i18n.t('errors.get_project_budgets')
      });
    }
  }
}

export const getProjectsAndContacts = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PROJECTS });

    try {
      let response = await api.post('bexio_call', {
        method: 'get',
        api_call: 'pr_project',
        payload: {}
      });
      let projects = [];
      let contacts = [];

      if (response.data && response.status === 200) {
        projects = response.data;
      }

      // wir brauchen contact nur für die projekte (-namen) darzustellen
      const usedContactIds = [...new Set(projects.map((p) => p.contact_id))];
      response = await api.post('bexio_call', {
        method: 'post',
        api_call: 'contact/search',
        payload: [{
          field: 'id',
          value: usedContactIds,
          criteria: 'in'
        }]
      });

      if (response.data && response.status === 200) {
        contacts = response.data;
      }

      dispatch({
        type: GET_PROJECTS_SUCCESS,
        projects,
        contacts
      });
    } catch (e) {
      dispatch({
        type: GET_PROJECTS_FAIL,
        error: i18n.t('errors.get_projects')
      });
    }
  }
}

const getActivities = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ACTIVITIES });

    try {
      const bexioCallParams = {
        method: 'get',
        api_call: 'client_service',
        payload: {}
      };

      const response = await api.post('bexio_call', bexioCallParams);

      if (!response.data || response.status !== 200) {
        throw response;
      }

      dispatch({
        type: GET_ACTIVITIES_SUCCESS,
        activities: response.data
      });
    } catch (e) {
      dispatch({
        type: GET_ACTIVITIES_FAIL,
        error: i18n.t('errors.get_activities')
      });
    }
  }
}

const getUsers = () => {
  return async (dispatch) => {
    dispatch({ type: GET_USERS });

    try {
      const bexioCallParams = {
        method: 'get',
        api_call: 'users',
        payload: {}
      };

      const response = await api.post('bexio_call', bexioCallParams);

      if (!response.data || response.status !== 200) {
        throw response;
      }

      dispatch({
        type: GET_USERS_SUCCESS,
        users: response.data
      });
    } catch (e) {
      dispatch({
        type: GET_USERS_FAIL,
        error: i18n.t('errors.get_users')
      });
    }
  }
};

// fetch and add vacation data to a single user
const getUserVacationData = (userId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_USER_VACATION_DATA });

    try {
      const { users } = getState().bexioData;
      const userIndex = users.findIndex(u => u.id === userId);
      const getMaxVacationParams = {
        params: {
          bexio_user_id: userId
        }
      }
      const userMaxVacationResponse = await api.get('ferien', getMaxVacationParams);
      let yearInitialBalances = {};
      userMaxVacationResponse.data.forEach(v => {
        yearInitialBalances[v.jahr] = v.anzahl_ferientage;
      });

      const getVacationTakenParams = {
        params: {
          bexio_user_id: userId,
        }
      }
      const vacationTakenResponse = await api.get('ferien_tage', getVacationTakenParams);

      const vacationTaken = {};
      vacationTakenResponse.data.forEach(day => {
        const year = moment(day.tag).get('year');
        vacationTaken[year] = vacationTaken[year] || {};
        vacationTaken[year][day.tag] = {
          id: day.id,
          amount: day.anzahl_ferientage
        }
      });

      users[userIndex]['vacationData'] = {
        yearInitialBalances,
        vacationTaken,
      };

      dispatch({
        type: GET_USER_VACATION_DATA_SUCCESS,
        users
      });
    } catch (e) {
      dispatch({
        type: GET_USER_VACATION_DATA_FAIL,
        error: i18n.t('errors.get_user_vacation_data')
      });
    }
  }
};

const setUserVacationData = (userId, newVacationData) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_USER_VACATION_DATA });

    const { users } = getState().bexioData;
    const userIndex = users.findIndex(u => u.id === userId);

    if(userIndex !== -1 && Object.keys(newVacationData).length > 0) {
      users[userIndex]['vacationData'] = newVacationData;
      dispatch({
        type: SET_USER_VACATION_DATA_SUCCESS,
        users
      });
    } else {
      dispatch({
        type: SET_USER_VACATION_DATA_FAIL,
        error: i18n.t('errors.set_user_vacation_data')
      });
    }
  }
};

// load all data from bexio - called at startup by dashboard
export const getAllBexioData = () => {
  return async (dispatch, getState) => {
    await getProjectsAndContacts()(dispatch);
    await getActivities()(dispatch);
    await getUsers()(dispatch);
    // user specific data must be last, so it may be linked to other data
    await getUserData()(dispatch, getState);
  }
}

export const getUserData = (userId) => {
  return async (dispatch, getState) => {
    const forUserId = userId || getState().auth.userID;
    const activeDayDate = getState().bexioData.dayData.date;

    await getTimeTrackings(forUserId)(dispatch, getState);
    await getWorkloadHistory(forUserId, activeDayDate)(dispatch);
    await getUserVacationData(forUserId)(dispatch, getState);
  }
}

export const getWeekData = (momentDate, forceReload = false) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_WEEK_DATA });

    const { bexioData } = getState();
    let calculatedWeek = bexioData.weekData;
    const isSameWeek = bexioData.weekData.some(day => day.date.isSame(momentDate));

    // only calculate week anew if necessary
    if(!isSameWeek || forceReload) {
      const weekDates = dateService.getWeekForDate(momentDate);

      // must contain 7 moment objects
      if (weekDates.length === 7 && !weekDates.some(obj => !obj._isAMomentObject)) {
        const yearSet = [...new Set(weekDates.map(day => day.get('year')))];
        const loadedYears = Object.keys(bexioData.workloadHistory).map(k => Number(k));
        const unloadedYears = yearSet.filter(y => !loadedYears.some(w => w === y));

        await Promise.all(
          unloadedYears.map(async year => {
            await getWorkloadHistory(bexioData.userIdForActiveData, moment(`${year}`, 'YYYY'))(dispatch);
          })
        );

        calculatedWeek = await calculateWeekData(weekDates, getState().bexioData);
      } else {
        dispatch({ type: GET_WEEK_DATA_FAIL, error: i18n.t('errors.get_week_data')});
        return;
      }
    }

    dispatch({ type: GET_WEEK_DATA_SUCCESS, weekData: calculatedWeek });
    // set dayData for the selected date in the new week
    getDayData(momentDate)(dispatch, getState);
  }
}

export const getDayData = (date) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_DAY_DATA });
    const preloadedDayData = getState().bexioData.weekData.find(day => day.date.isSame(date));

    if (preloadedDayData !== undefined) {
      dispatch({
        type: GET_DAY_DATA_SUCCESS,
        dayData: preloadedDayData
      })
    } else {
      dispatch({
        type: GET_DAY_DATA_FAIL,
        error: i18n.t('errors.get_day_data')
      })
    }
  }
}

export const addVacation = (onDate, addFullDay, upToDate) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_VACATION });
    try {
      const { bexioData } = getState();
      const forUserId = bexioData.userIdForActiveData;
      const newTimeTrackings = {...bexioData.timeTrackings};
      const newVacationData = bexioData.users.find(u => u.id === forUserId).vacationData;
      const untilDate = upToDate || onDate;

      for (let forDate = onDate.clone();
        forDate.diff(untilDate, 'days') <= 0;
        forDate.add(1, 'days')
      ) {
        const { targetMinutes: targetMinutesOnDate, workload: workloadOnDate } =
          bexioData.workloadHistory[forDate.get('year')][forDate.format('YYYY-MM-DD')];

        // day is a holiday or on a weekend, therefore no vacation can be used
        if (targetMinutesOnDate === 0) {
          continue;
        }

        // always base vacation calculations on the full target time of 8.4h
        // important for cases, where the day's target time is reduced, e.g. only half the usual target time on 24.12
        const targetMinutesForDate = 504 * workloadOnDate / 100

        const calculatedDuration = addFullDay ? dateService.getDurationFromMinutes(Math.ceil(targetMinutesForDate)) :
        dateService.getDurationFromMinutes(Math.ceil(targetMinutesForDate / 2));

        const bexioCallParams = {
          method: 'post',
          api_call: 'timesheet',
          payload: {
            "user_id": forUserId,
            "contact_id": 2,
            "pr_project_id": 1,
            "client_service_id": 6,
            "allowable_bill": true,
            "tracking": {
              "type": "duration",
              // bexio api doc says ISO8601 is required - but returns error if not DD.MM.YYYY
              "date": forDate.format("DD.MM.YYYY"),
              "duration": calculatedDuration
            },
            "text": i18n.t('vacation_text')
          }
        };

        const bexioResponse = await api.post('bexio_call', bexioCallParams);
        if (bexioResponse.status !== 201) {
          throw bexioResponse;
        }

        const ownApiResponse = await api.post('ferien_tage', {
          bexio_user_id: forUserId,
          tag: forDate.format('YYYY-MM-DD'),
          anzahl_ferientage: addFullDay ? 1 : 0.5
        });

        if (ownApiResponse.status !== 201) {
          throw ownApiResponse;
        }

        const bexioEntry = bexioResponse.data;
        if (newTimeTrackings[bexioEntry.date] !== undefined) {
          newTimeTrackings[bexioEntry.date].push(bexioEntry);
        } else {
          newTimeTrackings[bexioEntry.date] = [bexioEntry];
        }

        // initialize year key if the added vacation was the first for this year
        newVacationData.vacationTaken[forDate.get('year')] =
        newVacationData.vacationTaken[forDate.get('year')] || {};

        newVacationData.vacationTaken[forDate.get('year')][forDate.format('YYYY-MM-DD')] = {
          id: ownApiResponse.data.id,
          amount: addFullDay ? 1 : 0.5
        };
      }

      // update vacation data for the user
      await setUserVacationData(forUserId, newVacationData);

      const activeDateISO8601 = bexioData.dayData.date.format('YYYY-MM-DD');
      const newWeekAndDayData = await getNewWeekAndDayData(activeDateISO8601, {...bexioData, timeTrackings: newTimeTrackings});
      dispatch({
        type: ADD_VACATION_SUCCESS,
        newTimeTrackings,
        ...newWeekAndDayData
      });
    } catch (e) {
      const errorMsg = e && (String(e)).includes('403') ? i18n.t('errors.forbidden') :
      i18n.t('errors.add_timetracking');

      dispatch({
        type: ADD_VACATION_FAIL,
        error: errorMsg
      });
    }
  }
}

export const addTimeTracking = (entry) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_TIMETRACKING });
    try {
      const { bexioData } = getState();
      const onDate = entry.date || bexioData.dayData.date;
      const forUser = bexioData.users.find(u => u.id === bexioData.userIdForActiveData);

      if (!bexioData.projects.some(p => p.id === entry.projectId)) {
        dispatch({
          type: ADD_TIMETRACKING_FAIL,
          error: i18n.t('errors.project_is_archived')
        });
      }

      const bexioCallParams = {
        method: 'post',
        api_call: 'timesheet',
        payload: {
          "user_id": bexioData.userIdForActiveData,
          "contact_id": entry.clientId,
          "pr_project_id": entry.projectId,
          "client_service_id": entry.activityId,
          "allowable_bill": true,
          // 1 = "Offen"
          "status_id": 1,
          "tracking": {
            "type": "duration",
            // bexio api doc says ISO8601 is required - but returns error if not DD.MM.YYYY
            "date": onDate.format("DD.MM.YYYY"),
            "duration": entry.duration
          },
          "text": bexioEncoding.encodeTextForBexio(entry.text, forUser.firstname, forUser.lastname)
        }
      };

      const response = await api.post('bexio_call', bexioCallParams);
      if (response.status !== 201) {
        throw response;
      }

      const oldBexioData = {...bexioData};
      // if id exists, it means a timer was stopped => remove incomplete local entry
      if (entry.id) {
        oldBexioData.timeTrackings[response.data.date] =
        oldBexioData.timeTrackings[response.data.date].filter(t => t.id !== entry.id);
      }

      const newBexioData = await getNewBexioData(response.data, oldBexioData);

      dispatch({
        type: ADD_TIMETRACKING_SUCCESS,
        ...newBexioData
      });
    } catch (e) {
      const errorMsg = e && (String(e)).includes('403') ? i18n.t('errors.forbidden') :
      i18n.t('errors.add_timetracking');

      dispatch({
        type: ADD_TIMETRACKING_FAIL,
        error: errorMsg
      });
    }
  }
}

export const removeTimeTracking = (wasRunning, id) => {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_TIMETRACKING });
    const { users, userIdForActiveData, dayData, weekData, timeTrackings } = getState().bexioData;
    const wasOnDate = dayData.date.format('YYYY-MM-DD');
    const matchingEntry = timeTrackings[wasOnDate].find(t => t.id === id);

    // new data for the store - the timetracking will be removed anyway
    // but weekData and dayData will only be recalculated if it was successfully removed from bexio as well

    let newBexioData = {
      newTimeTrackings: {...timeTrackings},
      newWeekData: [...weekData],
      newDayData: {...dayData},
    };

    const removeFromTimeTrackings = () => {
      if (newBexioData.newTimeTrackings[wasOnDate].length > 1) {
        newBexioData.newTimeTrackings[wasOnDate] =
        newBexioData.newTimeTrackings[wasOnDate].filter(t => t.id !== id);
      } else {
        delete newBexioData.newTimeTrackings[wasOnDate];
      }
    };

    // if it was running (timer, existing only locally) only perform removal from store
    if (wasRunning) {
      removeFromTimeTrackings();
    } else {
      try {
        const bexioCallParams = {
          method: 'delete',
          api_call: `timesheet/${id}`,
          payload: {}
        };

        const bexioResponse = await api.post('bexio_call', bexioCallParams);
        if (bexioResponse.status !== 200) {
          throw bexioResponse;
        }

        // if entry was vacation, remove from own api and store as well
        if (matchingEntry.client_service_id === 6) {
          const newVacationData = users.find(u => u.id === userIdForActiveData).vacationData;
          const vacationDayId = newVacationData.vacationTaken[dayData.date.get('year')][matchingEntry.date].id;
          const ownApiResponse = await api.delete(`ferien_tage/${vacationDayId}`);
          if (ownApiResponse.status !== 200) {
            throw ownApiResponse;
          }

          // update vacation data for the user
          delete newVacationData.vacationTaken[dayData.date.get('year')][matchingEntry.date];
          await setUserVacationData(userIdForActiveData, newVacationData);
        }

        removeFromTimeTrackings();
        const newWeekAndDayData = await getNewWeekAndDayData(wasOnDate, {...getState().bexioData, timeTrackings: newBexioData.newTimeTrackings});
        newBexioData = {
          ...newBexioData,
          ...newWeekAndDayData
        };
      } catch (e) {
        const errorMsg = e && (String(e)).includes('403') ? i18n.t('errors.forbidden') :
        i18n.t('errors.remove_timetracking');
        dispatch({
          type: REMOVE_TIMETRACKING_FAIL,
          error: errorMsg
        });
        return;
      }
    }

    dispatch({
      type: REMOVE_TIMETRACKING_SUCCESS,
      ...newBexioData
    });
  }
}

export const editTimeTracking = (entry, oldDateISO8601) => {
  return async (dispatch, getState) => {
    dispatch({ type: EDIT_TIMETRACKING });

    try {
      // bexio api doc says ISO8601 is required - but returns error if not DD.MM.YYYY
      const dateFormatted = entry.date.format("DD.MM.YYYY");
      const { bexioData } = getState();
      const forUser = bexioData.users.find(u => u.id === bexioData.userIdForActiveData);

      if (!bexioData.projects.some(p => p.id === entry.projectId)) {
        dispatch({
          type: EDIT_TIMETRACKING_FAIL,
          error: i18n.t('errors.project_is_archived')
        });

        return;
      }

      const bexioCallParams = {
        method: 'post',
        api_call: `timesheet/${entry.id}`,
        payload: {
          "contact_id": entry.clientId,
          "pr_project_id": entry.projectId,
          "client_service_id": entry.activityId,
          "tracking": {
            "date": dateFormatted,
            "duration": entry.duration
          },
          "text": bexioEncoding.encodeTextForBexio(entry.text, forUser.firstname, forUser.lastname)
        }
      };

      const response = await api.post('bexio_call', bexioCallParams);
      if (response.status !== 200) {
        throw response;
      }

      const editedTimeTrackings = bexioData.timeTrackings;

      // remove existing timetracking at oldDate
      const indexOfExisting = editedTimeTrackings[oldDateISO8601]
        .findIndex(t => t.id === response.data.id);
      editedTimeTrackings[oldDateISO8601].splice(indexOfExisting, 1);

      // add edited timetracking at new Date
      if (editedTimeTrackings[response.data.date] !== undefined) {
        editedTimeTrackings[response.data.date].push(response.data);
      } else {
        editedTimeTrackings[response.data.date] = [response.data];
      }

      const newWeekAndDayData = await getNewWeekAndDayData(oldDateISO8601, {...bexioData, timeTrackings: editedTimeTrackings});
      dispatch({
        type: EDIT_TIMETRACKING_SUCCESS,
        editedTimeTrackings,
        ...newWeekAndDayData
      });
    } catch (e) {
      const errorMsg = e && (String(e)).includes('403') ? i18n.t('errors.forbidden') :
      i18n.t('errors.edit_timetracking');
      dispatch({
        type: EDIT_TIMETRACKING_FAIL,
        error: errorMsg
      });
    }
  }
}

export const startTimeTracking = (entry) => {
  return async (dispatch, getState) => {
    dispatch({ type: START_TIMETRACKING });

    if (getState().bexioData.projects.some(p => p.id === entry.projectId)) {
      entry.id = moment().unix();
      entry.startedAtUnixTimestamp = entry.id;
      entry.running = true;
      entry.date = getState().bexioData.dayData.date.format("YYYY-MM-DD");

      // will be loaded after loading all timetrackings from bexio
      localStorageService.setRunningTimeTracking(entry);

      dispatch({
        type: START_TIMETRACKING_SUCCESS,
        timeTracking: entry
      });
    } else {
      dispatch({
        type: START_TIMETRACKING_FAIL,
        error: i18n.t('errors.project_is_archived')
      });
    }
  }
}

export const continueTimeTracking = (entry) => {
  return async (dispatch, getState) => {
    dispatch({ type: CONTINUE_TIMETRACKING });

    if (getState().bexioData.timeTrackings[entry.date] &&
    getState().bexioData.timeTrackings[entry.date].some(t => t.id === entry.id)) {
      entry.running = true;
      entry.isContinuation = true;
      entry.startedAtUnixTimestamp = moment().unix();

      // will be loaded after loading all timetrackings from bexio
      localStorageService.setRunningTimeTracking(entry);

      dispatch({
        type: CONTINUE_TIMETRACKING_SUCCESS,
        timeTracking: entry
      });
    } else {
      dispatch({
        type: CONTINUE_TIMETRACKING_FAIL,
        error: i18n.t('errors.continue_timetracking')
      });
    }
  }
}

// <--------------------------------------------------------------------------------------->
// <-------------------------------- helper functions ------------------------------------->
// <--------------------------------------------------------------------------------------->

async function getNewBexioData(createdEntry, bexioData) {
  const newTimeTrackings = {...bexioData.timeTrackings};
  if (newTimeTrackings[createdEntry.date] !== undefined) {
    newTimeTrackings[createdEntry.date].push(createdEntry);
  } else {
    newTimeTrackings[createdEntry.date] = [createdEntry];
  }

  const newWeekAndDayData = await getNewWeekAndDayData(createdEntry.date, {...bexioData, timeTrackings: newTimeTrackings});
  return {
    newTimeTrackings,
    ...newWeekAndDayData
  }
}

async function getNewWeekAndDayData(dayDateISO8601, bexioData) {
  const weekDates = bexioData.weekData.map(day => day.date);
  const newWeekData = await calculateWeekData(weekDates, bexioData);
  const newDayData = newWeekData.find(day => day.date.format('YYYY-MM-DD') === dayDateISO8601) || bexioData.dayData;

  return {
    newWeekData,
    newDayData
  }
}

async function calculateWeekData(weekDates, bexioData) {
  const { users, workloadHistory, userIdForActiveData } = bexioData;
  const vacationTaken = users.find(u => u.id === userIdForActiveData).vacationData.vacationTaken;

  const weekData = await Promise.all(
    weekDates.map(async date => {
      const dateISO8601 = date.format("YYYY-MM-DD");
      const dayData = {
        ...workloadHistory[date.get('year')][dateISO8601],
        date,
        timeTrackings: [],
      };

      const timeTrackingsForDay = bexioData.timeTrackings[dateISO8601];
      if (timeTrackingsForDay !== undefined) {
        dayData.timeTrackings = timeTrackingsForDay.map(timeTracking =>
          utilService.resolveTimeTracking(timeTracking, bexioData)
        );
      }

      return utilService.addDayStatistics(dayData);
    })
  );

  // calculate the year/month timeBalance for the chosen week
  for (let i = 0; i < weekData.length; i++) {
    const momentDate = moment(weekData[i].date);
    const startOfYear = momentDate.clone().startOf('year');
    const startOfMonth = momentDate.clone().startOf('month');
    const isStartOfYear = startOfYear.isSame(momentDate);
    const isStartOfMonth = startOfMonth.isSame(momentDate);
    const hasPreviousWeekday = weekData[i-1] !== undefined;

    const yearStatistics = hasPreviousWeekday && !isStartOfYear ?
      {
        targetTimeYear: weekData[i-1].targetTimeYear + weekData[i].targetTimeIndustrial,
        workDoneYear: weekData[i-1].workDoneYear + weekData[i].workDoneIndustrial,
        timeBalanceYear: weekData[i-1].timeBalanceYear + weekData[i].difference
      } :
      getYearStatistics(weekData[i], bexioData);

    const monthStatistics = hasPreviousWeekday && !isStartOfMonth ?
      {
        targetTimeMonth: weekData[i-1].targetTimeMonth + weekData[i].targetTimeIndustrial,
        workDoneMonth: weekData[i-1].workDoneMonth + weekData[i].workDoneIndustrial,
        timeBalanceMonth: weekData[i-1].timeBalanceMonth + weekData[i].difference
      } :
      getMonthStatistics(weekData[i], bexioData);

    const vacationStatistics = hasPreviousWeekday && !isStartOfYear ?
      {
        yearInitialBalance: weekData[i-1].yearInitialBalance,
        vacationBalanceUsed: weekData[i-1].vacationBalanceUsed + utilService.getUsedVacationOnDate(vacationTaken, weekData[i].date),
        vacationBalanceLeft: weekData[i-1].yearInitialBalance - (weekData[i-1].vacationBalanceUsed + utilService.getUsedVacationOnDate(vacationTaken, weekData[i].date))
      } :
      utilService.getVacationStatistics(weekData[i].date, bexioData);

    weekData[i] = {
      ...weekData[i],
      ...yearStatistics,
      ...monthStatistics,
      ...vacationStatistics
    };
  }

  return weekData;
}

function getYearStatistics(untilDay, bexioData) {
  const { timeTrackings, workloadHistory } = bexioData;
  let workDoneYear = 0;
  let targetTimeYear = 0;
  let timeBalanceYear = 0;

  for (let startDate = moment(untilDay.date).startOf('year');
    startDate.diff(untilDay.date, 'days') <= 0;
    startDate.add(1, 'days')
  ) {
    const dateISO8601 = startDate.format('YYYY-MM-DD');
    const dayEntries = timeTrackings[dateISO8601];
    const workDoneMinutes = dayEntries === undefined ? 0 :
      dateService.getMinutesFromDurations(dayEntries.map(t => t.duration || '00:00'));

    const workDoneOnDay = workDoneMinutes / 60;
    workDoneYear += workDoneOnDay;

    const targetTimeOnDay = workloadHistory[startDate.get('year')][dateISO8601].targetMinutes / 60;
    targetTimeYear += targetTimeOnDay;

    timeBalanceYear += workDoneOnDay - targetTimeOnDay;
  }

  return {
    workDoneYear,
    targetTimeYear,
    timeBalanceYear
  };
}

function getMonthStatistics(untilDay, bexioData) {
  const { timeTrackings, workloadHistory } = bexioData;
  let workDoneMonth = 0;
  let targetTimeMonth = 0;
  let timeBalanceMonth = 0;

  for (let startDate = moment(untilDay.date).startOf('month');
    startDate.diff(untilDay.date, 'days') <= 0;
    startDate.add(1, 'days')
  ) {
    const dateISO8601 = startDate.format('YYYY-MM-DD');
    const dayEntries = timeTrackings[dateISO8601];
    const workDoneMinutes = dayEntries === undefined ? 0 :
      dateService.getMinutesFromDurations(dayEntries.map(t => t.duration || '00:00'));

    const workDoneOnDay = workDoneMinutes / 60;
    workDoneMonth += workDoneOnDay;

    const targetTimeOnDay = workloadHistory[startDate.get('year')][dateISO8601].targetMinutes / 60;
    targetTimeMonth += targetTimeOnDay;

    timeBalanceMonth += workDoneOnDay - targetTimeOnDay;
  }

  return {
    workDoneMonth,
    targetTimeMonth,
    timeBalanceMonth
  };
}
