import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { dateService } from '../../services';
import i18n from '../../i18n';
import { CenteringGrid } from '../common';

const styles = {
  root: {
    margin: '2px',
    overflowY: 'auto',
    width: '100%'
  },
  widgetTitle: {
    padding: '20px 0 15px 10px',
    fontSize: '22px',
  },
  container: {
    margin: '15px 10px 10px 10px',
    padding: '12px',
    'border': '2px solid #eee',
    borderBottom: '2px solid #eee',
    borderRadius: '5px',
  },
  containerTitle: {
    fontSize: '1.2rem',
  },
  normal: {
    fontSize: '1rem',
  },
  difference: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: '5px',
    borderTop: '1px solid #eee',
    borderBottom: '2px solid #eee',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  value: {
    float: 'right',
  },
  diffPositive: {
    float: 'right',
    color: 'green',
  },
  diffNegative: {
    float: 'right',
    color: 'red',
  }
};

function SummaryWidget({ classes, isLoadingBexioData, dayData }) {

  return (
    <div className={classes.root}>
      <Typography className={classes.widgetTitle}>{i18n.t('statistics.widget_title')}</Typography>

      {isLoadingBexioData && (
        <CenteringGrid>
          <CircularProgress />
        </CenteringGrid>
      )}

      {!isLoadingBexioData && (
        <React.Fragment>
          <div className={classes.container}>
            <Typography className={classes.containerTitle}>
              {i18n.t('statistics.daily')}
            </Typography>
            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.should')}
                <span className={classes.value}>
                  {dateService.prettyPrintIndustrialHours(dayData.targetTimeIndustrial)}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.is')}
                <span className={classes.value}>
                  {dateService.prettyPrintIndustrialHours(dayData.workDoneIndustrial)}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.difference}>{i18n.t('statistics.difference')}
                <span className={getDiffClass(dayData.difference, classes)}>
                  {dateService.prettyPrintIndustrialHours(dayData.difference)}
                </span>
              </Typography>
            </div>
          </div>

          <div className={classes.container}>
            <Typography className={classes.containerTitle}>
              {`${i18n.t('statistics.monthly')} (${i18n.t('statistics.since')} ${dayData.date.format('DD.MM')})`}
            </Typography>

            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.should')}
                <span className={classes.value}>
                  {dateService.prettyPrintIndustrialHours(dayData.targetTimeMonth)}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.is')}
                <span className={classes.value}>
                  {dateService.prettyPrintIndustrialHours(dayData.workDoneMonth)}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.difference}>{i18n.t('statistics.difference')}
                <span className={getDiffClass(dayData.timeBalanceMonth, classes)}>
                  {dateService.prettyPrintIndustrialHours(dayData.timeBalanceMonth)}
                </span>
              </Typography>
            </div>
          </div>

          <div className={classes.container}>
            <Typography className={classes.containerTitle}>
              {`${i18n.t('statistics.yearly')} (${i18n.t('statistics.since')} ${dayData.date.format('DD.MM')})`}
            </Typography>

            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.should')}
                <span className={classes.value}>
                  {dateService.prettyPrintIndustrialHours(dayData.targetTimeYear)}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.is')}
                <span className={classes.value}>
                  {dateService.prettyPrintIndustrialHours(dayData.workDoneYear)}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.difference}>{i18n.t('statistics.difference')}
                <span className={getDiffClass(dayData.timeBalanceYear, classes)}>
                  {dateService.prettyPrintIndustrialHours(dayData.timeBalanceYear)}
                </span>
              </Typography>
            </div>
          </div>

          <div className={classes.container}>
            <Typography className={classes.containerTitle}>
              {`${i18n.t('statistics.vacation_title')} (${i18n.t('statistics.since')} ${dayData.date.format('DD.MM')})`}
            </Typography>

            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.vacation_initial')}
                <span className={classes.value}>
                  {dayData.yearInitialBalance}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.normal}>{i18n.t('statistics.vacation_used')}
                <span className={classes.value}>
                  {dayData.vacationBalanceUsed}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.difference}>{i18n.t('statistics.vacation_left')}
                <span className={getDiffClass(dayData.vacationBalanceLeft, classes)}>
                  {dayData.vacationBalanceLeft}
                </span>
              </Typography>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function getDiffClass(diff, classes) {
  if (typeof diff !== 'number') {
    return classes.diffPositive;
  }

  return Number(diff.toFixed(2)) >= 0 ? classes.diffPositive : classes.diffNegative
}

SummaryWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  dayData: PropTypes.object.isRequired,
  isLoadingBexioData: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SummaryWidget);
