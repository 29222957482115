import React from 'react';
import Grid from '@material-ui/core/Grid';

function CenteringGrid({children}) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100%' }}
    >
      {children}
    </Grid>
  );
};

export default CenteringGrid;