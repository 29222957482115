import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/de-ch';
import {Provider} from "react-redux";
import {AuthProvider} from "react-oidc-context";
import {WebStorageStateStore} from "oidc-client-ts";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import {updateApiHeaders} from "./Api";
import store from "./Store";
import {setFromAuthUser} from "./actions";

moment.locale(navigator.language);

const oidcConfig = {
  authority: "https://idp.bexio.com/",
  client_id: "6ee83171-6bcb-4f73-865a-1cf718457df0",
  client_secret: "S1bMo3b0igTWtZ_ZZbxLw6MXQ6fAOg4Cy_2agEB2QUp_v0sIBzlLLi7A02INeqL279XFT3UTMlXnd9FV0G_qKQ",
  redirect_uri: window.location.origin,
  response_type: 'code',
  response_mode: 'query',
  scope: 'openid offline_access monitoring_edit project_show contact_show',
  filterProtocolClaims: true,
  onSigninCallback: (user) => {
    window.history.replaceState({}, document.title, window.location.pathname);
    updateApiHeaders(user);
    setFromAuthUser(user);
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
      <App/>
    </Provider>
  </AuthProvider>,
  document.getElementById('global')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
