import {
  SET_USER
} from '../actions/types';

const INITIAL_STATE = {
  id: null,
  userID: null,
  firstname: '',
  lastname: '',
  isAdmin: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user;
    default:
      return state;
  }
};
