export const CLEAR_ERRORS = 'clear_errors';

export const SET_USER = 'set_user';

export const GET_FAVORITES = 'get_favorites';
export const GET_FAVORITES_SUCCESS = 'get_favorites_success';
export const GET_FAVORITES_FAIL = 'get_favorites_fail';
export const ADD_FAVORITE = 'add_favorite';
export const ADD_FAVORITE_SUCCESS = 'add_favorite_success';
export const ADD_FAVORITE_FAIL = 'add_favorite_fail';
export const REMOVE_FAVORITE = 'remove_favorite';
export const REMOVE_FAVORITE_SUCCESS = 'remove_favorite_success';
export const REMOVE_FAVORITE_FAIL = 'remove_favorite_fail';

export const GET_WEEK_DATA = 'get_week_data';
export const GET_WEEK_DATA_SUCCESS = 'get_week_data_success';
export const GET_WEEK_DATA_FAIL = 'get_week_data_fail';
export const GET_DAY_DATA = 'get_day_data';
export const GET_DAY_DATA_SUCCESS = 'get_day_data_success';
export const GET_DAY_DATA_FAIL = 'get_day_data_fail';
export const ADD_VACATION = 'add_vacation';
export const ADD_VACATION_SUCCESS = 'add_vacation_success';
export const ADD_VACATION_FAIL = 'add_vacation_fail';
export const ADD_TIMETRACKING = 'add_timetracking';
export const ADD_TIMETRACKING_SUCCESS = 'add_timetracking_success';
export const ADD_TIMETRACKING_FAIL = 'add_timetracking_fail';
export const REMOVE_TIMETRACKING = 'remove_timetracking';
export const REMOVE_TIMETRACKING_SUCCESS = 'remove_timetracking_success';
export const REMOVE_TIMETRACKING_FAIL = 'remove_timetracking_fail';
export const EDIT_TIMETRACKING = 'edit_timetracking';
export const EDIT_TIMETRACKING_SUCCESS = 'edit_timetracking_success';
export const EDIT_TIMETRACKING_FAIL = 'edit_timetracking_fail';
export const START_TIMETRACKING = 'start_timetracking';
export const START_TIMETRACKING_SUCCESS = 'start_timetracking_success';
export const START_TIMETRACKING_FAIL = 'start_timetracking_fail';
export const CONTINUE_TIMETRACKING = 'continue_timetracking';
export const CONTINUE_TIMETRACKING_SUCCESS = 'continue_timetracking_success';
export const CONTINUE_TIMETRACKING_FAIL = 'continue_timetracking_fail';
export const GET_WORKLOAD_HISTORY = 'get_workload_history';
export const GET_WORKLOAD_HISTORY_SUCCESS = 'get_workload_history_success';
export const GET_WORKLOAD_HISTORY_FAIL = 'get_workload_history_fail';
export const GET_TIMETRACKINGS = 'get_timetrackings';
export const GET_TIMETRACKINGS_SUCCESS = 'get_timetrackings_success';
export const GET_TIMETRACKINGS_FAIL = 'get_timetrackings_fail';
export const GET_PROJECTS = 'get_projects';
export const GET_PROJECTS_SUCCESS = 'get_projects_success';
export const GET_PROJECTS_FAIL = 'get_projects_fail';
export const GET_ACTIVITIES = 'get_activities';
export const GET_ACTIVITIES_SUCCESS = 'get_activities_success';
export const GET_ACTIVITIES_FAIL = 'get_activities_fail';
export const GET_USERS = 'get_users';
export const GET_USERS_SUCCESS = 'get_users_success';
export const GET_USERS_FAIL = 'get_users_fail';
export const GET_USER_VACATION_DATA = 'get_user_vacation_data';
export const GET_USER_VACATION_DATA_SUCCESS = 'get_user_vacation_data_success';
export const GET_USER_VACATION_DATA_FAIL = 'get_user_vacation_data_fail';
export const SET_USER_VACATION_DATA = 'set_user_vacation_data';
export const SET_USER_VACATION_DATA_SUCCESS = 'set_user_vacation_data_success';
export const SET_USER_VACATION_DATA_FAIL = 'set_user_vacation_data_fail';
export const GET_PROJECT_BUDGETS = 'get_project_budgets';
export const GET_PROJECT_BUDGETS_SUCCESS = 'get_project_budgets_success';
export const GET_PROJECT_BUDGETS_FAIL = 'get_project_budgets_fail';
