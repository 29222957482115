import React from 'react';
import PropTypes from 'prop-types';

import CalendarBar from './CalendarBar';
import WeekBar from './WeekBar';

function DateWidget(props) {
  const { selectedDate, weekData, getWeekData, getDayData } = props;

  return (
    <div>
      <CalendarBar
        selectedDate={selectedDate}
        getWeekData={getWeekData}
      />

      <WeekBar
        weekData={weekData}
        selectedDate={selectedDate}
        getWeekData={getWeekData}
        getDayData={getDayData}
      />
    </div>
  );
}

DateWidget.propTypes = {
  weekData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDate: PropTypes.object.isRequired,
  getWeekData: PropTypes.func.isRequired,
  getDayData: PropTypes.func.isRequired,
};

export default DateWidget;
