// TODO: auth ist in eigenem store, das hier kann entfernt werden
function getAuth() {
  try {
    const serializedAuth = localStorage.getItem('oxotime_auth');
    if (serializedAuth === null) {
      return undefined;
    }
    return JSON.parse(serializedAuth);
  } catch (error) {
    return undefined;
  }
}

function setAuth(auth) {
  try {
    const serializedAuth = JSON.stringify(auth);
    localStorage.setItem('oxotime_auth', serializedAuth);
  } catch (error) {
    // ignore
  }
}

function resetAuth() {
  try {
    localStorage.removeItem('oxotime_auth');
  } catch (error) {
    // ignore
  }
}
// TODO fertig

function getRunningTimeTracking() {
  try {
    const serializedTimeTracking = localStorage.getItem('oxotime_running_timeTracking');
    if (serializedTimeTracking === null) {
      return undefined;
    }
    return JSON.parse(serializedTimeTracking);
  } catch (error) {
    return undefined;
  }
}

function setRunningTimeTracking(timeTracking) {
  if (timeTracking === undefined || timeTracking === null || typeof timeTracking !== 'object') {
    localStorage.removeItem('oxotime_running_timeTracking');
    return;
  }
  try {
    const serializedTimeTracking = JSON.stringify(timeTracking);
    localStorage.setItem('oxotime_running_timeTracking', serializedTimeTracking);
  } catch (error) {
    // ignore
  }
}

const localStorageService = {
  getAuth,
  setAuth,
  resetAuth,
  getRunningTimeTracking,
  setRunningTimeTracking,
};
export default localStorageService;
