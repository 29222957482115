import moment from 'moment';

import store from '../Store';
import i18n from '../i18n';
import utilService from './util';
import localStorageService from './localStorage';

const validationService = {
  newFavoriteIsValid,
  favoriteIdIsValid,
  yearInformationIsValid,
  canStartTimer,
  canContinueTimer,
  validateStateForTimeTracking,
  vacationStartIsValid,
  vacationEndIsValid
};

function newFavoriteIsValid(favJson) {
  // favJson contains the 3 keys clientId, projectId & activityId
  // their initial, non-valid value is 0
  if (Object.values(favJson).some(id => id === 0)) {
    alert(i18n.t('validation.invalid_favorite'));
    return false;
  }

  return true;
}

function favoriteIdIsValid(favorites, id) {
  const favorite = favorites.find(f => f.id === id);
  if (favorite === undefined) {
    return false;
  }

  return true;
}

function yearInformationIsValid(yearInformation) {
  if (!Array.isArray(yearInformation) || yearInformation.length < 365) {
    alert(i18n.t('validation.invalid_yearInformation'));
    return false;
  }

  return true;
}

function canStartTimer(duration) {
  if (duration !== '00:00' && duration !== '') {
    return false;
  }

  if (!store.getState().bexioData.dayData.date.isSame(moment().startOf('day'))) {
    alert(i18n.t('validation.timer_wrong_date'));
    return false;
  }

  if (!!localStorageService.getRunningTimeTracking()) {
    alert(i18n.t('validation.timer_already_running'));
    return false;
  }

  return true;
}

function canContinueTimer() {
  if (!store.getState().bexioData.dayData.date.isSame(moment().startOf('day'))) {
    return false;
  }

  if (!!localStorageService.getRunningTimeTracking()) {
    return false;
  }

  return true;
}

function validateStateForTimeTracking(formState) {
  if (formState.text.length === 0) {
    formState.textError = i18n.t('validation.text_missing');
    formState.valid = false;
    return formState;
  } else {
    formState.textError = '';
  }

  if (formState.duration === '00:00' || formState.duration === '') {
    formState.durationError = i18n.t('validation.no_default_duration');
    formState.valid = false;
    return formState;
  }

  // try parse hours:minutes
  const [hoursPart, minutesPart] = formState.duration.split(':');
  if (
    hoursPart.length > 0
    && !hoursPart.includes('.')
    && !hoursPart.includes(',')
    && minutesPart?.length === 2
  ) {
    const hoursNumber = Number(hoursPart);
    const minutesNumber = Number(minutesPart);

    if (
      !isNaN(hoursNumber)
      && !isNaN(minutesNumber)
      && minutesNumber < 60
      && ((hoursNumber * 60) + minutesNumber) > 0
    ) {
      formState.valid = true;
      return formState;
    }
  }

  // try parse decimal hours
  const minutes = formState.duration.replace(',', '.') * 60;
  if (!isNaN(minutes) && minutes > 0) {
    formState.valid = true;
    return formState;
  }

  formState.durationError = i18n.t('validation.invalid_duration');
  formState.valid = false;
  return formState;
}

function vacationStartIsValid(vacationStartDate) {
  const vacationStartISO8601 = vacationStartDate.format('YYYY-MM-DD');
  const onYear = vacationStartDate.get('year');
  const { workloadHistory } = store.getState().bexioData;

  if(workloadHistory[onYear] === undefined ||
  workloadHistory[onYear][vacationStartISO8601] === undefined) {
    alert(i18n.t('validation.year_data_missing'));
    return false;
  }

  if(workloadHistory[onYear][vacationStartISO8601].isCompleted) {
    alert(i18n.t('validation.invalid_vacation_start'));
    return false;
  }

  return true;
}

function vacationEndIsValid(vacationStartDate, vacationEndDate, isFullDay) {
  const { bexioData } = store.getState();

  let requiredVacationDays = 0;
  for (let startDate = vacationStartDate.clone();
    startDate.diff(vacationEndDate, 'days') <= 0;
    startDate.add(1, 'days')
  ) {
    const dateISO8601 = startDate.format('YYYY-MM-DD');
    const onYear = startDate.get('year');
    if (bexioData.workloadHistory[onYear][dateISO8601].targetMinutes > 0) {
      requiredVacationDays += isFullDay ? 1 : 0.5;
    }
  }

  const previousVacationStatistics = utilService.getVacationStatistics(vacationStartDate, bexioData);
  const thisYearLeft = previousVacationStatistics.vacationBalanceLeft;
  // simple case: start/end are on same year, check if enough vacation left until end of year
  if (vacationStartDate.get('year') === vacationEndDate.get('year')) {
    if(requiredVacationDays > thisYearLeft) {
      alert(i18n.t('validation.vacation_balance_exceeded'));
      return false;
    }
    return true;
  }

  // year-break case: start/end are on adjacent years
  // check if vacation left of start year PLUS vacation left in end year at end date are sufficient
  if (vacationStartDate.get('year') === vacationEndDate.get('year') - 1) {
    const nextYearVacationStatistics = utilService.getVacationStatistics(vacationEndDate, bexioData);
    const nextYearLeft = nextYearVacationStatistics.vacationBalanceLeft;
    if(requiredVacationDays > (thisYearLeft + nextYearLeft)) {
      alert(i18n.t('validation.vacation_balance_exceeded'));
      return false;
    }
    return true;
  }

  // user tried to enter vacation for more than a full year (gap > 1)
  return false;
}

export default validationService;