import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useAuth, hasAuthParams} from "react-oidc-context";
import ScreensDashboard from "./screens/Dashboard";
import {CircularProgress} from "@material-ui/core";
import {CenteringGrid, MainAppBar} from "./components/common";
import {MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import theme from "./Theme";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import {updateApiHeaders} from "./Api";
import {setFromAuthUser} from "./actions";

// siehe github wie Open ID Connect mit diesem package genau funktioniert: https://github.com/authts/react-oidc-context

function App({ bexioUsers, currentUser, setFromAuthUser }) {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
    if (auth.isAuthenticated) {
      updateApiHeaders(auth.user);
      setFromAuthUser(auth.user);
    }
  }, [auth, hasTriedSignin, bexioUsers]);

  // refresh access token
  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth
        .signinSilent()
        .then(user => {
          updateApiHeaders(user);
          setFromAuthUser(user);
        });
    })
  }, [auth.events, auth.signinSilent]);

  if (auth.isLoading || !currentUser?.userID) {
    return (
      <CenteringGrid>
        <CircularProgress/>
      </CenteringGrid>
    );
  }

  if (auth.error) {
    return <div>Fehler beim Authentifizieren: {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    return <div>Kann nicht einloggen</div>;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div style={{display: "flex", flexDirection: 'column', height: '100%'}}>
          <MainAppBar/>
          <ScreensDashboard/>
        </div>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    bexioUsers: state.bexioData.users,
    currentUser: state.auth,
  };
};

export default connect(mapStateToProps, {setFromAuthUser})(App);
