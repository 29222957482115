import React from 'react';
import { Select, Input, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import i18n from '../../i18n';

const styles = theme => ({
  select: {
    width: '100%',
    height: '50px',
    backgroundColor: '#EEEEEE',
    marginBottom: '15px',
  },
  selectRoot: {
    paddingLeft: '12px',
    marginTop: '4px',
  },
})

function SelectFavoriteModular(props) {
  const { classes, isDisabled, contacts, projectsForClient, activities, chosenClientId, chosenProjectId,
  chosenActivityId, handleClientChange, handleProjectChange, handleActivityChange } = props;

  return (
    <React.Fragment>
      <FormControl className={classes.select}>
        <InputLabel variant='filled' shrink htmlFor="choose-client">
          {i18n.t('customer')}
        </InputLabel>
        <Select
          disabled={isDisabled}
          classes={{"root": classes.selectRoot}}
          value={chosenClientId}
          onChange={handleClientChange}
          input={<Input name="choose-client" id="choose-client" autoFocus />}
        >
          {contacts.map((c) => (
            <MenuItem value={c.id} key={c.id}>{c.name_1}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.select}>
        <InputLabel variant='filled' shrink htmlFor="choose-project">
          {i18n.t('project')}
        </InputLabel>
        <Select
          disabled={isDisabled}
          classes={{"root": classes.selectRoot}}
          value={chosenProjectId}
          onChange={handleProjectChange}
          input={<Input name="choose-project" id="choose-project" />}
        >
          {projectsForClient.map((p) => (
            <MenuItem value={p.id} key={p.id}>{p.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.select}>
        <InputLabel variant='filled' shrink htmlFor="choose-activity">
          {i18n.t('activity')}
        </InputLabel>
        <Select
          disabled={isDisabled}
          classes={{"root": classes.selectRoot}}
          value={chosenActivityId}
          onChange={handleActivityChange}
          input={<Input name="choose-activity" id="choose-activity" />}
        >
          {activities.map((a) => (
            <MenuItem value={a.id} key={a.id}>{a.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  )
}

SelectFavoriteModular.propTypes = {
  isDisabled: PropTypes.bool,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectsForClient: PropTypes.array.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  chosenClientId: PropTypes.number.isRequired,
  chosenProjectId: PropTypes.number.isRequired,
  chosenActivityId: PropTypes.number.isRequired,
  handleClientChange: PropTypes.func.isRequired,
  handleProjectChange: PropTypes.func.isRequired,
  handleActivityChange: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true})(SelectFavoriteModular);
