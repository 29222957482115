import React, { Component, Fragment } from 'react';
import { CircularProgress, Button, List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AddVacationDialog from './AddVacationDialog';
import RemoveFavoriteDialog from './RemoveFavoriteDialog';
import AddFavoriteDialog from './AddFavoriteDialog';
import AddTimeTrackingDialog from '../TimeTracking/AddTimeTrackingDialog';
import Favorite from './Favorite';
import { CenteringGrid } from '../common';
import i18n from '../../i18n';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  widgetTitle: {
    fontSize: 22,
    padding: '20px 0 15px 10px'
  },
  clientTitle: {
    fontSize: '1.2rem',
    "text-transform": 'uppercase',
  },
  favoritesContainer: {
    overflowY: 'auto',
    flex: '1 0 0',
  },
  buttonArea: {
    padding: '20px',
    paddingLeft: '10px',
  },
  button: {
    width: '100%',
  },
  editButton: {
    width: '100%',
    marginTop: '20px',
    border: '2px solid transparent',
  },
  doneButton: {
    width: '100%',
    marginTop: '20px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: '2px solid #cdcdcd',
  },
})

class FavoriteWidget extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    favorites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientId: PropTypes.number.isRequired,
        projectId: PropTypes.number.isRequired,
        activityId: PropTypes.number.isRequired,
        client: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        activity: PropTypes.string.isRequired,
        combinedName: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    isLoadingFavorites: PropTypes.bool.isRequired,
    isLoadingBexioData: PropTypes.bool.isRequired,
    isOwnData: PropTypes.bool.isRequired,
    dayIsCompleted: PropTypes.bool.isRequired,
    addFavorite: PropTypes.func.isRequired,
    startTimeTracking: PropTypes.func.isRequired,
    removeFavorites: PropTypes.func.isRequired,
    addTimeTracking: PropTypes.func.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  state = {
    showAddVacation: false,
    showAddFavorite: false,
    showRemoveFavorite: false,
    showAddTimeTracking: false,
    chosenFavorite: this.props.favorites[0] || {
      id: 0,
      clientId: 0,
      projectId: 0,
      activityId: 0,
      client: '',
      project: '',
      activity: '',
      combinedName: ''
    },
    editMode: false,
  };

  toggleEditDialog = (forTimeTracking = this.state.chosenTimeTracking) => {
    this.setState({
      showEditDialog: !this.state.showEditDialog,
      chosenTimeTracking: {...forTimeTracking},
     });
  };

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  };

  toggleShowAddVacation = () => {
    this.setState({ showAddVacation: !this.state.showAddVacation });
  };

  toggleShowAddFavorite = () => {
    this.setState({ showAddFavorite: !this.state.showAddFavorite });
  };

  toggleShowRemoveFavorite = (favorite = this.state.chosenFavorite) => {
    this.setState({ showRemoveFavorite: !this.state.showRemoveFavorite });
    this.setState({ chosenFavorite: favorite });
  };

  toggleShowAddTimeTracking = (favorite = this.state.chosenFavorite) => {
    this.setState({ showAddTimeTracking: !this.state.showAddTimeTracking });
    this.setState({ chosenFavorite: favorite });
  };

  handleFavoriteChange = event => {
    const matchedFavorite = this.props.favorites.find(f => f.id === event.target.value);
    this.setState({ chosenFavorite: matchedFavorite });
  };

  render() {
    const { classes, isLoadingFavorites, isLoadingBexioData, favorites, contacts, projects, activities, isOwnData,
      dayIsCompleted, addFavorite, startTimeTracking, removeFavorites, addTimeTracking } = this.props;
    const { editMode, showAddVacation, showAddFavorite, showRemoveFavorite  } = this.state;

    // group favorites by client in a new object
    const favoritesByClient = {};
    const uniqueClients = [...new Set(favorites.map((f) => f.client))];
    uniqueClients.forEach(client => {
      favoritesByClient[client] = favorites.filter(f => f.client === client);
    });

    return (
      <div className={classes.root}>
        {isLoadingFavorites && (
          <CenteringGrid>
            <CircularProgress />
          </CenteringGrid>
        )}

        {!isLoadingFavorites && (
          <Fragment>
            <div className={classes.favoritesContainer}>
              {
                Object.keys(favoritesByClient).map(client => (
                  <List key={client}>
                    <ListItem>
                      <ListItemText primary={client} classes={{primary: classes.clientTitle}} />
                    </ListItem>

                    {favoritesByClient[client].map((favorite) => (
                      <Favorite
                        key={favorite.id}
                        data={favorite}
                        isOwnData={isOwnData}
                        dayIsCompleted={dayIsCompleted}
                        editMode={editMode}
                        showRemove={this.toggleShowRemoveFavorite}
                        showAddTimeTracking={this.toggleShowAddTimeTracking}
                      />
                    ))}
                  </List>
                ))
              }
            </div>

            <div className={classes.buttonArea}>
              {!editMode &&
              <Fragment>
                {isLoadingBexioData && (
                  <CenteringGrid>
                    <CircularProgress />
                  </CenteringGrid>
                )}
                {!isLoadingBexioData && (
                  <Button
                    className={classes.button}
                    onClick={() => this.toggleShowAddVacation()}
                    color="primary"
                    variant="contained"
                  >
                    {i18n.t('add_vacation')}
                  </Button>
                )}
                <Button className={classes.editButton} onClick={() => this.toggleEditMode()}>
                  {i18n.t('edit')}
                </Button>
              </Fragment>
              }

              {editMode &&
                <Fragment>
                  <Button
                    className={classes.button}
                    onClick={() => this.toggleShowAddFavorite()}
                    color="primary"
                    variant="contained"
                  >
                    {i18n.t('add_favorite')}
                  </Button>
                  <Button
                    className={classes.doneButton}
                    onClick={() => this.toggleEditMode()}
                  >
                    {i18n.t('done')}
                  </Button>
                </Fragment>
              }
            </div>

            <AddVacationDialog
              open={showAddVacation}
              handleClose={this.toggleShowAddVacation}
            />

            <AddFavoriteDialog
              open={showAddFavorite}
              handleClose={this.toggleShowAddFavorite}
              addFavorite={addFavorite}
              contacts={contacts}
              projects={projects.filter(p => p.pr_state_id !== 3)}
              activities={activities}
            />

            <RemoveFavoriteDialog
              open={showRemoveFavorite}
              handleClose={this.toggleShowRemoveFavorite}
              chosenFavorite={this.state.chosenFavorite}
              removeFavorites={removeFavorites}
            />

            <AddTimeTrackingDialog
              open={this.state.showAddTimeTracking}
              isOwnData={isOwnData}
              handleClose={this.toggleShowAddTimeTracking}
              contacts={contacts}
              projects={projects.filter(p => p.pr_state_id !== 3)}
              activities={activities}
              favorites={favorites}
              chosenFavorite={this.state.chosenFavorite}
              handleFavoriteChange={this.handleFavoriteChange}
              startTimeTracking={startTimeTracking}
              addTimeTracking={addTimeTracking}
            />
          </Fragment>
        )}
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true})(FavoriteWidget);
