import i18n from 'i18n-js';

import de from './translations/de.json';
import en from './translations/en.json';

i18n.locale = navigator.language;
i18n.fallbacks = true;
i18n.translations = { de, en };

export default i18n;
