import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';

import i18n from '../i18n';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const isVisible = value === index;

  return (
    <div
      // Tabs container has min-height 48 + 16 padding bottom
      style={{ height: 'calc(100% - 64px)', ...(isVisible && { display: 'flex' }) }}
      role="tabpanel"
      hidden={!isVisible}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isVisible && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function FavoritenBudgetsTabs({ favoritenJsx, budgetsJsx }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Tabs
        style={{ paddingBottom: '16px' }}
        value={value}
        onChange={handleChange}
        aria-label="Favoriten-Budgets Navigation"
        variant="fullWidth"
      >
        <Tab label={i18n.t('favorites')} {...a11yProps(0)} />
        <Tab label={i18n.t('budgets')} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {favoritenJsx}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {budgetsJsx}
      </TabPanel>
    </div>
  );
}

FavoritenBudgetsTabs.propTypes = {
  favoritenJsx: PropTypes.node.isRequired,
  budgetsJsx: PropTypes.node.isRequired,
};

export default FavoritenBudgetsTabs;
