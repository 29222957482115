import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tabs, Tab } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import moment from 'moment';

import { dateService } from '../../services';
import i18n from '../../i18n';

const styles = theme => ({
  upperText: {
    fontSize: '1rem',
  },
})

class WeekBar extends Component {
  static propTypes = {
  classes: PropTypes.object.isRequired,
  weekData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDate: PropTypes.object.isRequired,
  getWeekData: PropTypes.func.isRequired,
  getDayData: PropTypes.func.isRequired,
};

  handleChange = (event, value) => {
    if (value === 'lastWeek') {
      const dateOneWeekAgo = this.props.selectedDate.clone().subtract(7, 'd');
      const lastWeekFriday = dateOneWeekAgo.isoWeekday(5); // friday
      this.props.getWeekData(lastWeekFriday);
    } else if (value === 'nextWeek') {
      const dateOneWeekLater = this.props.selectedDate.clone().add(7, 'd');
      const nextWeekMonday = dateOneWeekLater.isoWeekday(1); // monday
      this.props.getWeekData(nextWeekMonday);
    } else {
      this.props.getDayData(moment(value, 'X'));
    }
  };

  render() {
    const { classes, weekData, selectedDate } = this.props;

    // make sure data has fully loaded
    if (weekData.length === 0 || weekData[0].date === undefined
    || !weekData.some(day => day.date.isSame(selectedDate))) {
      return null;
    }

    const weeklyWorkDone = dateService.getDurationFromMinutes(
      weekData.map(day => day.workDoneMinutes).reduce((a,b) => a + b)
    );

    return (
      <Tabs
        value={selectedDate.format("X")}
        onChange={this.handleChange}
        indicatorColor='primary'
      >
        <Tab
          value={'lastWeek'}
          icon={<ChevronLeft />}
        />

        {weekData.map((day) => {
          return (
            <Tab
              key={day.date.format("X")}
              value={day.date.format("X")}
              label={
                <Typography>
                  <span className={classes.upperText}>{day.date.format("dd")}</span>
                  <br/>
                  {day.workDoneDuration}
                </Typography>
              }
            />
          )
        })}

        <Tab
          disabled
          label={
            <Typography>
              <span className={classes.upperText}>{i18n.t('total')}</span>
              <br />
              {weeklyWorkDone}
            </Typography>
          }
        />

        <Tab
          value={'nextWeek'}
          icon={<ChevronRight />}
        />
      </Tabs>
    );
  }
}

export default withStyles(styles, { withTheme: true})(WeekBar);